import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, TAB_SIZE } from "../../constants/ThemeSetting";
const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const { width, themeType } = useSelector(({ settings }) => settings);
    let navStyle = useSelector(({ settings }) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }
    return (_jsx("div", {}));
};
export default SidebarLogo;
