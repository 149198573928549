import * as coreClient from "@azure/core-client";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
export class ApiClient extends coreClient.ServiceClient {
    /**
     * Initializes a new instance of the ApiClient class.
     * @param credentials Subscription credentials which uniquely identify client subscription.
     * @param $host server parameter
     * @param options The parameter options
     */
    constructor(credentials, $host, options) {
        var _a, _b;
        if (credentials === undefined) {
            throw new Error("'credentials' cannot be null");
        }
        if ($host === undefined) {
            throw new Error("'$host' cannot be null");
        }
        // Initializing default values for options
        if (!options) {
            options = {};
        }
        const defaults = {
            requestContentType: "application/json; charset=utf-8",
            credential: credentials
        };
        const packageDetails = `azsdk-js-apiClient/1.0.0-beta.1`;
        const userAgentPrefix = options.userAgentOptions && options.userAgentOptions.userAgentPrefix
            ? `${options.userAgentOptions.userAgentPrefix} ${packageDetails}`
            : `${packageDetails}`;
        const optionsWithDefaults = Object.assign(Object.assign(Object.assign({}, defaults), options), { userAgentOptions: {
                userAgentPrefix
            }, baseUri: (_b = (_a = options.endpoint) !== null && _a !== void 0 ? _a : options.baseUri) !== null && _b !== void 0 ? _b : "{$host}" });
        super(optionsWithDefaults);
        // Parameter assignments
        this.$host = $host;
    }
    /** @param options The options parameters. */
    getApplications(options) {
        return this.sendOperationRequest({ options }, getApplicationsOperationSpec);
    }
    /**
     * Детальный просмотр заявки
     * @param options The options parameters.
     */
    getApplicationDetails(options) {
        return this.sendOperationRequest({ options }, getApplicationDetailsOperationSpec);
    }
    /**
     * Загрузка и разбор заявок из JSON файла
     * @param options The options parameters.
     */
    importApplications(options) {
        return this.sendOperationRequest({ options }, importApplicationsOperationSpec);
    }
    /**
     * Импорт заявок
     * @param options The options parameters.
     */
    createApplications(options) {
        return this.sendOperationRequest({ options }, createApplicationsOperationSpec);
    }
    /**
     * Заполнение заявки пассажирами
     * @param options The options parameters.
     */
    fillApplicationWithPassengers(options) {
        return this.sendOperationRequest({ options }, fillApplicationWithPassengersOperationSpec);
    }
    /**
     * Заявка. Пакет обновления МО -> Фонд
     * @param options The options parameters.
     */
    updateApplicationsBulk(options) {
        return this.sendOperationRequest({ options }, updateApplicationsBulkOperationSpec);
    }
    /**
     * Заявка. Пакет обновления МО -> Фонд (через файл applications_YYYY_MM_DD_hh_mm_ss.json)
     * @param options The options parameters.
     */
    updateApplicationsBulkWithFile(options) {
        return this.sendOperationRequest({ options }, updateApplicationsBulkWithFileOperationSpec);
    }
    /**
     * Заявка полная. Фонд -> МО
     * @param options The options parameters.
     */
    updateApplicationsFull(options) {
        return this.sendOperationRequest({ options }, updateApplicationsFullOperationSpec);
    }
    /**
     * Заявка полная. Фонд -> МО (через файл application_full_applicationNum_YYYY_MM_DD_hh_mm_ss.json)
     * @param options The options parameters.
     */
    updateApplicationsFullWithFile(options) {
        return this.sendOperationRequest({ options }, updateApplicationsFullWithFileOperationSpec);
    }
    /**
     * Заявка частичная по статусам Фонд -> МО
     * @param options The options parameters.
     */
    updateApplicationsStatuses(options) {
        return this.sendOperationRequest({ options }, updateApplicationsStatusesOperationSpec);
    }
    /**
     * Заявка частичная по статусам Фонд -> МО (через файл application_status_YYYY_MM_DD_hh_mm_ss.json)
     * @param options The options parameters.
     */
    updateApplicationsStatusesWithFile(options) {
        return this.sendOperationRequest({ options }, updateApplicationsStatusesWithFileOperationSpec);
    }
    /**
     * Заявка частичная по пассажирам. Фонд -> МО
     * @param options The options parameters.
     */
    updateApplicationsPassengers(options) {
        return this.sendOperationRequest({ options }, updateApplicationsPassengersOperationSpec);
    }
    /**
     * Заявка частичная по пассажирам. Фонд -> МО (через файл
     * application_passengers_applicationNum_YYYY_MM_DD_hh_mm_ss.json)
     * @param options The options parameters.
     */
    updateApplicationsPassengersWithFile(options) {
        return this.sendOperationRequest({ options }, updateApplicationsPassengersWithFileOperationSpec);
    }
    /**
     * Заявка частичная по явке пассажиров. Фонд -> МО
     * @param options The options parameters.
     */
    updateApplicationsPassengersAttendance(options) {
        return this.sendOperationRequest({ options }, updateApplicationsPassengersAttendanceOperationSpec);
    }
    /**
     * Заявка частичная по явке пассажиров. Фонд -> МО (через файл
     * applications_hascome_YYYY_MM_DD_hh_mm_ss.json)
     * @param options The options parameters.
     */
    updateApplicationsPassengersAttendanceWithFile(options) {
        return this.sendOperationRequest({ options }, updateApplicationsPassengersAttendanceWithFileOperationSpec);
    }
    /** @param options The options parameters. */
    exchange(options) {
        return this.sendOperationRequest({ options }, exchangeOperationSpec);
    }
    /** @param options The options parameters. */
    blockReasonList(options) {
        return this.sendOperationRequest({ options }, blockReasonListOperationSpec);
    }
    /** @param options The options parameters. */
    blockReasonPost(options) {
        return this.sendOperationRequest({ options }, blockReasonPostOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    blockReasonPatch(id, options) {
        return this.sendOperationRequest({ id, options }, blockReasonPatchOperationSpec);
    }
    /** @param options The options parameters. */
    budgetDetailedList(options) {
        return this.sendOperationRequest({ options }, budgetDetailedListOperationSpec);
    }
    /** @param options The options parameters. */
    budgetPost(options) {
        return this.sendOperationRequest({ options }, budgetPostOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    budgetPatch(id, options) {
        return this.sendOperationRequest({ id, options }, budgetPatchOperationSpec);
    }
    /** @param options The options parameters. */
    getBudgets(options) {
        return this.sendOperationRequest({ options }, getBudgetsOperationSpec);
    }
    /** @param options The options parameters. */
    createBudgets(options) {
        return this.sendOperationRequest({ options }, createBudgetsOperationSpec);
    }
    /** @param options The options parameters. */
    updateBudget(options) {
        return this.sendOperationRequest({ options }, updateBudgetOperationSpec);
    }
    /** @param options The options parameters. */
    getBudgetList(options) {
        return this.sendOperationRequest({ options }, getBudgetListOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    getBudget(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, getBudgetOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    getBudgetByClaimNumber(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, getBudgetByClaimNumberOperationSpec);
    }
    /**
     * @param listId
     * @param options The options parameters.
     */
    getCandidateDocumentsList(listId, options) {
        return this.sendOperationRequest({ listId, options }, getCandidateDocumentsListOperationSpec);
    }
    /**
     * @param listId
     * @param options The options parameters.
     */
    uploadCandidateDocumentsList(listId, options) {
        return this.sendOperationRequest({ listId, options }, uploadCandidateDocumentsListOperationSpec);
    }
    /** @param options The options parameters. */
    getCandidateMemberLists(options) {
        return this.sendOperationRequest({ options }, getCandidateMemberListsOperationSpec);
    }
    /**
     * @param candidateId
     * @param options The options parameters.
     */
    getListsbyCandidateDuplicates(candidateId, options) {
        return this.sendOperationRequest({ candidateId, options }, getListsbyCandidateDuplicatesOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getCandidateMemberListsByMemberId(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getCandidateMemberListsByMemberIdOperationSpec);
    }
    /**
     * @param listId
     * @param options The options parameters.
     */
    getCandidateMemberListById(listId, options) {
        return this.sendOperationRequest({ listId, options }, getCandidateMemberListByIdOperationSpec);
    }
    /**
     * @param listId
     * @param options The options parameters.
     */
    projects(listId, options) {
        return this.sendOperationRequest({ listId, options }, projectsOperationSpec);
    }
    /** @param options The options parameters. */
    addMemberAccesses(options) {
        return this.sendOperationRequest({ options }, addMemberAccessesOperationSpec);
    }
    /** @param options The options parameters. */
    setCandidatesAsMembers(options) {
        return this.sendOperationRequest({ options }, setCandidatesAsMembersOperationSpec);
    }
    /** @param options The options parameters. */
    parseExcel(options) {
        return this.sendOperationRequest({ options }, parseExcelOperationSpec);
    }
    /** @param options The options parameters. */
    saveParsedCandidateMemberList(options) {
        return this.sendOperationRequest({ options }, saveParsedCandidateMemberListOperationSpec);
    }
    /** @param options The options parameters. */
    getCandidateMembers(options) {
        return this.sendOperationRequest({ options }, getCandidateMembersOperationSpec);
    }
    /** @param options The options parameters. */
    createCandidateMember(options) {
        return this.sendOperationRequest({ options }, createCandidateMemberOperationSpec);
    }
    /** @param options The options parameters. */
    updateCandidateMember(options) {
        return this.sendOperationRequest({ options }, updateCandidateMemberOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getCandidateMemberById(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getCandidateMemberByIdOperationSpec);
    }
    /** @param options The options parameters. */
    notMembers(options) {
        return this.sendOperationRequest({ options }, notMembersOperationSpec);
    }
    /** @param options The options parameters. */
    replaceCandidateEmail(options) {
        return this.sendOperationRequest({ options }, replaceCandidateEmailOperationSpec);
    }
    /** @param options The options parameters. */
    linkCandidateToExistedMember(options) {
        return this.sendOperationRequest({ options }, linkCandidateToExistedMemberOperationSpec);
    }
    /** @param options The options parameters. */
    acceptCertificatePaymentRegistry(options) {
        return this.sendOperationRequest({ options }, acceptCertificatePaymentRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    setStatusCertificatePaymentRegistry(options) {
        return this.sendOperationRequest({ options }, setStatusCertificatePaymentRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    deleteCertificatePaymentRegistry(options) {
        return this.sendOperationRequest({ options }, deleteCertificatePaymentRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    dashboardCertificatePaymentRegistryOfGroups(options) {
        return this.sendOperationRequest({ options }, dashboardCertificatePaymentRegistryOfGroupsOperationSpec);
    }
    /** @param options The options parameters. */
    dashboardReplacementCertificatePaymentRegistryOfGroups(options) {
        return this.sendOperationRequest({ options }, dashboardReplacementCertificatePaymentRegistryOfGroupsOperationSpec);
    }
    /**
     * Получение списка заявок с рекомендуемыми заменами по группе
     * @param options The options parameters.
     */
    getReplacementPaymentRegistryItemByGroup(options) {
        return this.sendOperationRequest({ options }, getReplacementPaymentRegistryItemByGroupOperationSpec);
    }
    /**
     * Получение списка заявок с рекомендуемыми заменами по id элементов в реестрах
     * @param options The options parameters.
     */
    getReplacementPaymentRegistryItemByItemIds(options) {
        return this.sendOperationRequest({ options }, getReplacementPaymentRegistryItemByItemIdsOperationSpec);
    }
    /**
     * Заявки, учтённые в реестре
     * @param options The options parameters.
     */
    dashboardCertificatePaymentRegistryOfRegistryClaims(options) {
        return this.sendOperationRequest({ options }, dashboardCertificatePaymentRegistryOfRegistryClaimsOperationSpec);
    }
    /**
     * Заявки для замены
     * @param options The options parameters.
     */
    dashboardReplacementCertificatePaymentRegistryOfRegistryClaims(options) {
        return this.sendOperationRequest({ options }, dashboardReplacementCertificatePaymentRegistryOfRegistryClaimsOperationSpec);
    }
    /**
     * Заявки, НЕ в реестре
     * @param options The options parameters.
     */
    dashboardNoRegistryClaims(options) {
        return this.sendOperationRequest({ options }, dashboardNoRegistryClaimsOperationSpec);
    }
    /**
     * Заявки, НЕ в реестре
     * @param options The options parameters.
     */
    dashboardRegistryAnyClaims(options) {
        return this.sendOperationRequest({ options }, dashboardRegistryAnyClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    parseRegistryExcel(options) {
        return this.sendOperationRequest({ options }, parseRegistryExcelOperationSpec);
    }
    /** @param options The options parameters. */
    getCertificatePaymentRegistries(options) {
        return this.sendOperationRequest({ options }, getCertificatePaymentRegistriesOperationSpec);
    }
    /**
     * @param certificatePaymentRegistryId
     * @param options The options parameters.
     */
    getCertificatePaymentRegistry(certificatePaymentRegistryId, options) {
        return this.sendOperationRequest({ certificatePaymentRegistryId, options }, getCertificatePaymentRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    getCertificatePaymentRegistryStatuses(options) {
        return this.sendOperationRequest({ options }, getCertificatePaymentRegistryStatusesOperationSpec);
    }
    /** @param options The options parameters. */
    preCreateRegistry(options) {
        return this.sendOperationRequest({ options }, preCreateRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    getPaymentRegistryByGroup(options) {
        return this.sendOperationRequest({ options }, getPaymentRegistryByGroupOperationSpec);
    }
    /** @param options The options parameters. */
    getPaymentRegistryByClaims(options) {
        return this.sendOperationRequest({ options }, getPaymentRegistryByClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getCorrectingPaymentRegistryByItems(options) {
        return this.sendOperationRequest({ options }, getCorrectingPaymentRegistryByItemsOperationSpec);
    }
    /** @param options The options parameters. */
    newRegistry(options) {
        return this.sendOperationRequest({ options }, newRegistryOperationSpec);
    }
    /** @param options The options parameters. */
    getCertificates(options) {
        return this.sendOperationRequest({ options }, getCertificatesOperationSpec);
    }
    /** @param options The options parameters. */
    createCertificate(options) {
        return this.sendOperationRequest({ options }, createCertificateOperationSpec);
    }
    /** @param options The options parameters. */
    updateCertificate(options) {
        return this.sendOperationRequest({ options }, updateCertificateOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    getCertificateDetailed(id, options) {
        return this.sendOperationRequest({ id, options }, getCertificateDetailedOperationSpec);
    }
    /** @param options The options parameters. */
    getFreeCertificates(options) {
        return this.sendOperationRequest({ options }, getFreeCertificatesOperationSpec);
    }
    /**
     * @param certificateId
     * @param options The options parameters.
     */
    getCertificateById(certificateId, options) {
        return this.sendOperationRequest({ certificateId, options }, getCertificateByIdOperationSpec);
    }
    /** @param options The options parameters. */
    attachCertificateAndClaimToMemberAccess(options) {
        return this.sendOperationRequest({ options }, attachCertificateAndClaimToMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    groupAttachCertificateAndClaimToMemberAccess(options) {
        return this.sendOperationRequest({ options }, groupAttachCertificateAndClaimToMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    detachCertificateAndClaimToMemberAccess(options) {
        return this.sendOperationRequest({ options }, detachCertificateAndClaimToMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    transferCertificateAndClaimToMemberAccess(options) {
        return this.sendOperationRequest({ options }, transferCertificateAndClaimToMemberAccessOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    claimDocumentsUpload(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, claimDocumentsUploadOperationSpec);
    }
    /**
     * @param fileId
     * @param options The options parameters.
     */
    claimDocumentDelete(fileId, options) {
        return this.sendOperationRequest({ fileId, options }, claimDocumentDeleteOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    claimResultDocumentsUpload(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, claimResultDocumentsUploadOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    claimResultAdmDocumentsUpload(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, claimResultAdmDocumentsUploadOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    resultClaimDocumentDirectUpload(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, resultClaimDocumentDirectUploadOperationSpec);
    }
    /**
     * @param groupNumber
     * @param options The options parameters.
     */
    groupClaimsResultDocumentsUpload(groupNumber, options) {
        return this.sendOperationRequest({ groupNumber, options }, groupClaimsResultDocumentsUploadOperationSpec);
    }
    /**
     * @param groupId
     * @param options The options parameters.
     */
    groupClaimsDocumentsUpload(groupId, options) {
        return this.sendOperationRequest({ groupId, options }, groupClaimsDocumentsUploadOperationSpec);
    }
    /**
     * @param claimId
     * @param statusId
     * @param options The options parameters.
     */
    setClaimDocumentStatus(claimId, statusId, options) {
        return this.sendOperationRequest({ claimId, statusId, options }, setClaimDocumentStatusOperationSpec);
    }
    /**
     * @param groupId
     * @param statusId
     * @param options The options parameters.
     */
    setGroupClaimDocumentStatus(groupId, statusId, options) {
        return this.sendOperationRequest({ groupId, statusId, options }, setGroupClaimDocumentStatusOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getClaimFiles(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getClaimFilesOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getResultClaimFilesArchive(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getResultClaimFilesArchiveOperationSpec);
    }
    /** @param options The options parameters. */
    getClaimsDocumentsArchive(options) {
        return this.sendOperationRequest({ options }, getClaimsDocumentsArchiveOperationSpec);
    }
    /** @param options The options parameters. */
    getClaimDocumentsArchive(options) {
        return this.sendOperationRequest({ options }, getClaimDocumentsArchiveOperationSpec);
    }
    /** @param options The options parameters. */
    getUnreadedClaims(options) {
        return this.sendOperationRequest({ options }, getUnreadedClaimsOperationSpec);
    }
    /**
     * @param tourGroupId
     * @param options The options parameters.
     */
    getClaimsListForSpecifiedTourGroup(tourGroupId, options) {
        return this.sendOperationRequest({ tourGroupId, options }, getClaimsListForSpecifiedTourGroupOperationSpec);
    }
    /** @param options The options parameters. */
    getClaimsList(options) {
        return this.sendOperationRequest({ options }, getClaimsListOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getClaimById(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getClaimByIdOperationSpec);
    }
    /**
     * @param externalTourGroupId
     * @param options The options parameters.
     */
    getTourGroupMembersListExcel(externalTourGroupId, options) {
        return this.sendOperationRequest({ externalTourGroupId, options }, getTourGroupMembersListExcelOperationSpec);
    }
    /** @param options The options parameters. */
    getClaimDocumentsSendingStatuses(options) {
        return this.sendOperationRequest({ options }, getClaimDocumentsSendingStatusesOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    setClaimStatusConfirmed(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, setClaimStatusConfirmedOperationSpec);
    }
    /** @param options The options parameters. */
    setClaimsListStatusConfirmed(options) {
        return this.sendOperationRequest({ options }, setClaimsListStatusConfirmedOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    setClaimMemberAbsent(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, setClaimMemberAbsentOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    setClaimAmounts(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, setClaimAmountsOperationSpec);
    }
    /**
     * @param externalTourGroupId
     * @param options The options parameters.
     */
    getTourGroupClaimsListExcel(externalTourGroupId, options) {
        return this.sendOperationRequest({ externalTourGroupId, options }, getTourGroupClaimsListExcelOperationSpec);
    }
    /** @param options The options parameters. */
    getClaimCounts(options) {
        return this.sendOperationRequest({ options }, getClaimCountsOperationSpec);
    }
    /** @param options The options parameters. */
    getFilteredClaims(options) {
        return this.sendOperationRequest({ options }, getFilteredClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getClients(options) {
        return this.sendOperationRequest({ options }, getClientsOperationSpec);
    }
    /** @param options The options parameters. */
    createClient(options) {
        return this.sendOperationRequest({ options }, createClientOperationSpec);
    }
    /**
     * @param clientId
     * @param options The options parameters.
     */
    deleteClient(clientId, options) {
        return this.sendOperationRequest({ clientId, options }, deleteClientOperationSpec);
    }
    /** @param options The options parameters. */
    getCompetitions(options) {
        return this.sendOperationRequest({ options }, getCompetitionsOperationSpec);
    }
    /** @param options The options parameters. */
    createCompetition(options) {
        return this.sendOperationRequest({ options }, createCompetitionOperationSpec);
    }
    /** @param options The options parameters. */
    updateCompetition(options) {
        return this.sendOperationRequest({ options }, updateCompetitionOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getClaimMessages(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getClaimMessagesOperationSpec);
    }
    /**
     * @param messageId
     * @param options The options parameters.
     */
    getClaimMessage(messageId, options) {
        return this.sendOperationRequest({ messageId, options }, getClaimMessageOperationSpec);
    }
    /** @param options The options parameters. */
    createClaimMessage(options) {
        return this.sendOperationRequest({ options }, createClaimMessageOperationSpec);
    }
    /** @param options The options parameters. */
    markMessagesAsRead(options) {
        return this.sendOperationRequest({ options }, markMessagesAsReadOperationSpec);
    }
    /** @param options The options parameters. */
    setMessageStatus(options) {
        return this.sendOperationRequest({ options }, setMessageStatusOperationSpec);
    }
    /** @param options The options parameters. */
    uploadMessageFile(options) {
        return this.sendOperationRequest({ options }, uploadMessageFileOperationSpec);
    }
    /** @param options The options parameters. */
    dashboardStatisticsOfGroups(options) {
        return this.sendOperationRequest({ options }, dashboardStatisticsOfGroupsOperationSpec);
    }
    /**
     * @param externalTourId
     * @param options The options parameters.
     */
    getTourDetailsPdf(externalTourId, options) {
        return this.sendOperationRequest({ externalTourId, options }, getTourDetailsPdfOperationSpec);
    }
    /** @param options The options parameters. */
    getFilteredMemberStatistics(options) {
        return this.sendOperationRequest({ options }, getFilteredMemberStatisticsOperationSpec);
    }
    /** @param options The options parameters. */
    getMemberStatistics(options) {
        return this.sendOperationRequest({ options }, getMemberStatisticsOperationSpec);
    }
    /** @param options The options parameters. */
    createInfoMessage(options) {
        return this.sendOperationRequest({ options }, createInfoMessageOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    getInfoMessageByClaimNumber(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, getInfoMessageByClaimNumberOperationSpec);
    }
    /** @param options The options parameters. */
    rejectInfoMessage(options) {
        return this.sendOperationRequest({ options }, rejectInfoMessageOperationSpec);
    }
    /** @param options The options parameters. */
    getInfoMessages(options) {
        return this.sendOperationRequest({ options }, getInfoMessagesOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    getStatusByClaimNumber(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, getStatusByClaimNumberOperationSpec);
    }
    /**
     * @param accIntegration
     * @param options The options parameters.
     */
    getInfoMessageRequests(accIntegration, options) {
        return this.sendOperationRequest({ accIntegration, options }, getInfoMessageRequestsOperationSpec);
    }
    /**
     * @param accIntegration
     * @param options The options parameters.
     */
    getInfoMessageRequestStatuses(accIntegration, options) {
        return this.sendOperationRequest({ accIntegration, options }, getInfoMessageRequestStatusesOperationSpec);
    }
    /**
     * @param accIntegration
     * @param options The options parameters.
     */
    setInfoMessageRequestStatus(accIntegration, options) {
        return this.sendOperationRequest({ accIntegration, options }, setInfoMessageRequestStatusOperationSpec);
    }
    /**
     * @param fileId
     * @param options The options parameters.
     */
    getFile(fileId, options) {
        return this.sendOperationRequest({ fileId, options }, getFileOperationSpec);
    }
    /**
     * @param file
     * @param options The options parameters.
     */
    postFile(file, options) {
        return this.sendOperationRequest({ file, options }, postFileOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    getExpiringMemberAccesses(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, getExpiringMemberAccessesOperationSpec);
    }
    /** @param options The options parameters. */
    updateParticipationCertificate(options) {
        return this.sendOperationRequest({ options }, updateParticipationCertificateOperationSpec);
    }
    /** @param options The options parameters. */
    getParticpationCertificates(options) {
        return this.sendOperationRequest({ options }, getParticpationCertificatesOperationSpec);
    }
    /**
     * @param accessId
     * @param options The options parameters.
     */
    getMemberAccessById(accessId, options) {
        return this.sendOperationRequest({ accessId, options }, getMemberAccessByIdOperationSpec);
    }
    /**
     * @param accessId
     * @param options The options parameters.
     */
    extendMemberAccess(accessId, options) {
        return this.sendOperationRequest({ accessId, options }, extendMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    blockMemberAccess(options) {
        return this.sendOperationRequest({ options }, blockMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    createMemberAccess(options) {
        return this.sendOperationRequest({ options }, createMemberAccessOperationSpec);
    }
    /** @param options The options parameters. */
    getMemberAccessList(options) {
        return this.sendOperationRequest({ options }, getMemberAccessListOperationSpec);
    }
    /** @param options The options parameters. */
    getFilteredMemberAccesses(options) {
        return this.sendOperationRequest({ options }, getFilteredMemberAccessesOperationSpec);
    }
    /** @param options The options parameters. */
    getMemberList(options) {
        return this.sendOperationRequest({ options }, getMemberListOperationSpec);
    }
    /** @param options The options parameters. */
    getFilteredMembers(options) {
        return this.sendOperationRequest({ options }, getFilteredMembersOperationSpec);
    }
    /** @param options The options parameters. */
    getInvitedMemberList(options) {
        return this.sendOperationRequest({ options }, getInvitedMemberListOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getMember(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getMemberOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getMemberCard(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getMemberCardOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getMemberWithProviderCheck(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getMemberWithProviderCheckOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getMemberWithAccesses(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getMemberWithAccessesOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    getMemberChangeDataRequestList(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, getMemberChangeDataRequestListOperationSpec);
    }
    /** @param options The options parameters. */
    postMemberChangeDataRequest(options) {
        return this.sendOperationRequest({ options }, postMemberChangeDataRequestOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    attachFileToMemberProfileChangeDataRequest(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, attachFileToMemberProfileChangeDataRequestOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    approveMemberChangeDataRequest(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, approveMemberChangeDataRequestOperationSpec);
    }
    /**
     * @param requestId
     * @param options The options parameters.
     */
    rejectMemberChangeDataRequest(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, rejectMemberChangeDataRequestOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    generateFastLoginLinkForMember(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, generateFastLoginLinkForMemberOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    checkMemberPasswordIsSet(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, checkMemberPasswordIsSetOperationSpec);
    }
    /**
     * @param memberId
     * @param options The options parameters.
     */
    changeMemberEmail(memberId, options) {
        return this.sendOperationRequest({ memberId, options }, changeMemberEmailOperationSpec);
    }
    /**
     * @param groupId
     * @param options The options parameters.
     */
    getBookingPapersByGroupId(groupId, options) {
        return this.sendOperationRequest({ groupId, options }, getBookingPapersByGroupIdOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getBookingPaper(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getBookingPaperOperationSpec);
    }
    /** @param options The options parameters. */
    prelimitaryAgentReportDatePeriods(options) {
        return this.sendOperationRequest({ options }, prelimitaryAgentReportDatePeriodsOperationSpec);
    }
    /**
     * @param periodDate
     * @param options The options parameters.
     */
    providersOfTourGroupsFinishedAtSpecificMonth(periodDate, options) {
        return this.sendOperationRequest({ periodDate, options }, providersOfTourGroupsFinishedAtSpecificMonthOperationSpec);
    }
    /**
     * @param providerId
     * @param periodDate
     * @param options The options parameters.
     */
    getPreliminaryAgentReportExcel(providerId, periodDate, options) {
        return this.sendOperationRequest({ providerId, periodDate, options }, getPreliminaryAgentReportExcelOperationSpec);
    }
    /**
     * @param providerId
     * @param periodDate
     * @param options The options parameters.
     */
    getFinalAgentReportExcel(providerId, periodDate, options) {
        return this.sendOperationRequest({ providerId, periodDate, options }, getFinalAgentReportExcelOperationSpec);
    }
    /** @param options The options parameters. */
    getInfoMessagesLogbook(options) {
        return this.sendOperationRequest({ options }, getInfoMessagesLogbookOperationSpec);
    }
    /**
     * @param periodDate
     * @param options The options parameters.
     */
    getListOfAgentReportStatuses(periodDate, options) {
        return this.sendOperationRequest({ periodDate, options }, getListOfAgentReportStatusesOperationSpec);
    }
    /**
     * @param periodDate
     * @param options The options parameters.
     */
    createAgentReport(periodDate, options) {
        return this.sendOperationRequest({ periodDate, options }, createAgentReportOperationSpec);
    }
    /**
     * @param agentReportId
     * @param options The options parameters.
     */
    setAgentReportSigned(agentReportId, options) {
        return this.sendOperationRequest({ agentReportId, options }, setAgentReportSignedOperationSpec);
    }
    /**
     * Список статусов работы отдела бронирования
     * @param options The options parameters.
     */
    getListOfReservationDeptStatuses(options) {
        return this.sendOperationRequest({ options }, getListOfReservationDeptStatusesOperationSpec);
    }
    /**
     * Смена статуса работы отдела бронирования для отчета агента
     * @param agentReportId
     * @param options The options parameters.
     */
    setAgentReportReservationStatus(agentReportId, options) {
        return this.sendOperationRequest({ agentReportId, options }, setAgentReportReservationStatusOperationSpec);
    }
    /**
     * Получает содержимое информационного письма
     * @param requestId Id запроса информационного сообщения
     * @param options The options parameters.
     */
    informationNoticeGet(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, informationNoticeGetOperationSpec);
    }
    /**
     * Обновляет содержимое информационного письма
     * @param requestId Id запроса информационного сообщения
     * @param options The options parameters.
     */
    informationNoticeUpdate(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, informationNoticeUpdateOperationSpec);
    }
    /**
     * Запуск отправки информационного письма
     * @param requestId Id запроса информационного сообщения
     * @param options The options parameters.
     */
    informationNoticeSend(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, informationNoticeSendOperationSpec);
    }
    /**
     * Повторная отправка информационного письма
     * @param requestId Id запроса информационного сообщения
     * @param options The options parameters.
     */
    informationNoticeResend(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, informationNoticeResendOperationSpec);
    }
    /**
     * Создание docx документа c телом информационного письма на основе данных с формы
     * @param options The options parameters.
     */
    informationNoticeDocumentCreate(options) {
        return this.sendOperationRequest({ options }, informationNoticeDocumentCreateOperationSpec);
    }
    /**
     * Создание превью docx документа c телом информационного письма на основе данных с формы, с
     * сохранением пропорций
     * @param width Ширина в px
     * @param height Высота в px
     * @param options The options parameters.
     */
    informationNoticeDocumentCreateThumbnail(width, height, options) {
        return this.sendOperationRequest({ width, height, options }, informationNoticeDocumentCreateThumbnailOperationSpec);
    }
    /**
     * Создание превью загруженного документа docx
     * @param width
     * @param height
     * @param options The options parameters.
     */
    informationNoticeUploadDocument(width, height, options) {
        return this.sendOperationRequest({ width, height, options }, informationNoticeUploadDocumentOperationSpec);
    }
    /**
     * Запуск отправки информационного письма
     * @param requestId Id запроса информационного сообщения
     * @param options The options parameters.
     */
    informationNoticeArtifactSend(requestId, options) {
        return this.sendOperationRequest({ requestId, options }, informationNoticeArtifactSendOperationSpec);
    }
    /** @param options The options parameters. */
    getProgramList(options) {
        return this.sendOperationRequest({ options }, getProgramListOperationSpec);
    }
    /** @param options The options parameters. */
    programPost(options) {
        return this.sendOperationRequest({ options }, programPostOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    getProgram(id, options) {
        return this.sendOperationRequest({ id, options }, getProgramOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    programPatch(id, options) {
        return this.sendOperationRequest({ id, options }, programPatchOperationSpec);
    }
    /** @param options The options parameters. */
    getProgramsByProvider(options) {
        return this.sendOperationRequest({ options }, getProgramsByProviderOperationSpec);
    }
    /**
     * Получение списка программ и бюджетов по id поставщика (полный список без ограничения userId)
     * @param options The options parameters.
     */
    getProgramsByProviderId(options) {
        return this.sendOperationRequest({ options }, getProgramsByProviderIdOperationSpec);
    }
    /** @param options The options parameters. */
    getProjects(options) {
        return this.sendOperationRequest({ options }, getProjectsOperationSpec);
    }
    /** @param options The options parameters. */
    createProject(options) {
        return this.sendOperationRequest({ options }, createProjectOperationSpec);
    }
    /** @param options The options parameters. */
    updateProject(options) {
        return this.sendOperationRequest({ options }, updateProjectOperationSpec);
    }
    /**
     * @param projectId
     * @param options The options parameters.
     */
    getProject(projectId, options) {
        return this.sendOperationRequest({ projectId, options }, getProjectOperationSpec);
    }
    /**
     * @param projectId
     * @param options The options parameters.
     */
    removeProject(projectId, options) {
        return this.sendOperationRequest({ projectId, options }, removeProjectOperationSpec);
    }
    /**
     * @param providerId
     * @param userId
     * @param options The options parameters.
     */
    linkUserAccessToProvider(providerId, userId, options) {
        return this.sendOperationRequest({ providerId, userId, options }, linkUserAccessToProviderOperationSpec);
    }
    /**
     * @param providerId
     * @param userId
     * @param options The options parameters.
     */
    dropUserAccessToProvider(providerId, userId, options) {
        return this.sendOperationRequest({ providerId, userId, options }, dropUserAccessToProviderOperationSpec);
    }
    /**
     * @param providerId
     * @param options The options parameters.
     */
    getTourOperatorUsers(providerId, options) {
        return this.sendOperationRequest({ providerId, options }, getTourOperatorUsersOperationSpec);
    }
    /** @param options The options parameters. */
    linkUserAccessToMultipleProviders(options) {
        return this.sendOperationRequest({ options }, linkUserAccessToMultipleProvidersOperationSpec);
    }
    /** @param options The options parameters. */
    dropUserAccessToProviders(options) {
        return this.sendOperationRequest({ options }, dropUserAccessToProvidersOperationSpec);
    }
    /** @param options The options parameters. */
    usersWithProviderAccess(options) {
        return this.sendOperationRequest({ options }, usersWithProviderAccessOperationSpec);
    }
    /**
     * @param userId
     * @param options The options parameters.
     */
    getUserProvidersAccesses(userId, options) {
        return this.sendOperationRequest({ userId, options }, getUserProvidersAccessesOperationSpec);
    }
    /**
     * Получение провайдера
     * @param options The options parameters.
     */
    getProviderById(options) {
        return this.sendOperationRequest({ options }, getProviderByIdOperationSpec);
    }
    /** @param options The options parameters. */
    providerList(options) {
        return this.sendOperationRequest({ options }, providerListOperationSpec);
    }
    /** @param options The options parameters. */
    providerPost(options) {
        return this.sendOperationRequest({ options }, providerPostOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    providerGet(id, options) {
        return this.sendOperationRequest({ id, options }, providerGetOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    providerPatch(id, options) {
        return this.sendOperationRequest({ id, options }, providerPatchOperationSpec);
    }
    /**
     * @param providerId
     * @param budgetId
     * @param options The options parameters.
     */
    providerLinkToBudget(providerId, budgetId, options) {
        return this.sendOperationRequest({ providerId, budgetId, options }, providerLinkToBudgetOperationSpec);
    }
    /** @param options The options parameters. */
    getFilteredResultClaims(options) {
        return this.sendOperationRequest({ options }, getFilteredResultClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getStatisticsByOperatorsResultClaims(options) {
        return this.sendOperationRequest({ options }, getStatisticsByOperatorsResultClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getStatisticsResultClaims(options) {
        return this.sendOperationRequest({ options }, getStatisticsResultClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getResultClaimTourOperatorStatuses(options) {
        return this.sendOperationRequest({ options }, getResultClaimTourOperatorStatusesOperationSpec);
    }
    /** @param options The options parameters. */
    getResultClaimAdministratorStatuses(options) {
        return this.sendOperationRequest({ options }, getResultClaimAdministratorStatusesOperationSpec);
    }
    /** @param options The options parameters. */
    getResultClaimCheckStatuses(options) {
        return this.sendOperationRequest({ options }, getResultClaimCheckStatusesOperationSpec);
    }
    /** @param options The options parameters. */
    setResultClaimTourOperatorStatus(options) {
        return this.sendOperationRequest({ options }, setResultClaimTourOperatorStatusOperationSpec);
    }
    /** @param options The options parameters. */
    setResultClaimAdministratorStatus(options) {
        return this.sendOperationRequest({ options }, setResultClaimAdministratorStatusOperationSpec);
    }
    /** @param options The options parameters. */
    setGroupResultClaimTourOperatorStatus(options) {
        return this.sendOperationRequest({ options }, setGroupResultClaimTourOperatorStatusOperationSpec);
    }
    /** @param options The options parameters. */
    setGroupResultClaimAdministratorStatus(options) {
        return this.sendOperationRequest({ options }, setGroupResultClaimAdministratorStatusOperationSpec);
    }
    /** @param options The options parameters. */
    setGroupResultClaimResultCheckStatus(options) {
        return this.sendOperationRequest({ options }, setGroupResultClaimResultCheckStatusOperationSpec);
    }
    /**
     * @param claimId
     * @param options The options parameters.
     */
    getResultClaimById(claimId, options) {
        return this.sendOperationRequest({ claimId, options }, getResultClaimByIdOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    getResultClaimByNumber(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, getResultClaimByNumberOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    resultClaimSetCheckStatus(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, resultClaimSetCheckStatusOperationSpec);
    }
    /** @param options The options parameters. */
    getBookingCenterClaimCheckStatuses(options) {
        return this.sendOperationRequest({ options }, getBookingCenterClaimCheckStatusesOperationSpec);
    }
    /** @param options The options parameters. */
    resultClaimDocumentDownload(options) {
        return this.sendOperationRequest({ options }, resultClaimDocumentDownloadOperationSpec);
    }
    /** @param options The options parameters. */
    setResponsibleClaim(options) {
        return this.sendOperationRequest({ options }, setResponsibleClaimOperationSpec);
    }
    /** @param options The options parameters. */
    setCommentClaim(options) {
        return this.sendOperationRequest({ options }, setCommentClaimOperationSpec);
    }
    /** @param options The options parameters. */
    getResultGroups(options) {
        return this.sendOperationRequest({ options }, getResultGroupsOperationSpec);
    }
    /**
     * @param resultGroupId
     * @param options The options parameters.
     */
    getResultGroupById(resultGroupId, options) {
        return this.sendOperationRequest({ resultGroupId, options }, getResultGroupByIdOperationSpec);
    }
    /** @param options The options parameters. */
    updateResultGroup(options) {
        return this.sendOperationRequest({ options }, updateResultGroupOperationSpec);
    }
    /** @param options The options parameters. */
    uploadConfirmationMail(options) {
        return this.sendOperationRequest({ options }, uploadConfirmationMailOperationSpec);
    }
    /** @param options The options parameters. */
    getRoles(options) {
        return this.sendOperationRequest({ options }, getRolesOperationSpec);
    }
    /** @param options The options parameters. */
    createRole(options) {
        return this.sendOperationRequest({ options }, createRoleOperationSpec);
    }
    /** @param options The options parameters. */
    deleteRole(options) {
        return this.sendOperationRequest({ options }, deleteRoleOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvProjects(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvProjectsOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvCandidateLists(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvCandidateListsOperationSpec);
    }
    /**
     * @param budgetId
     * @param accIntegration
     * @param options The options parameters.
     */
    rsvCandidates(budgetId, accIntegration, options) {
        return this.sendOperationRequest({ budgetId, accIntegration, options }, rsvCandidatesOperationSpec);
    }
    /**
     * @param accIntegration
     * @param options The options parameters.
     */
    acceptHashedRecords(accIntegration, options) {
        return this.sendOperationRequest({ accIntegration, options }, acceptHashedRecordsOperationSpec);
    }
    /**
     * @param budgetId
     * @param accIntegration
     * @param options The options parameters.
     */
    rsvMembers(budgetId, accIntegration, options) {
        return this.sendOperationRequest({ budgetId, accIntegration, options }, rsvMembersOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvMembersCandidates(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvMembersCandidatesOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvCertificates(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvCertificatesOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvPaymentRegistries(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvPaymentRegistriesOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvPaymentRegistry(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvPaymentRegistryOperationSpec);
    }
    /**
     * @param budgetId
     * @param options The options parameters.
     */
    rsvPaymentRegistrySetStatus(budgetId, options) {
        return this.sendOperationRequest({ budgetId, options }, rsvPaymentRegistrySetStatusOperationSpec);
    }
    /** @param options The options parameters. */
    getRsvResultList(options) {
        return this.sendOperationRequest({ options }, getRsvResultListOperationSpec);
    }
    /** @param options The options parameters. */
    searchTourOrTourGroupById(options) {
        return this.sendOperationRequest({ options }, searchTourOrTourGroupByIdOperationSpec);
    }
    /** @param options The options parameters. */
    searchMembers(options) {
        return this.sendOperationRequest({ options }, searchMembersOperationSpec);
    }
    /** @param options The options parameters. */
    searchClaims(options) {
        return this.sendOperationRequest({ options }, searchClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getAccessTokensByUser(options) {
        return this.sendOperationRequest({ options }, getAccessTokensByUserOperationSpec);
    }
    /** @param options The options parameters. */
    getAccessToken(options) {
        return this.sendOperationRequest({ options }, getAccessTokenOperationSpec);
    }
    /**
     * @param accessTokenId
     * @param options The options parameters.
     */
    extendAccess(accessTokenId, options) {
        return this.sendOperationRequest({ accessTokenId, options }, extendAccessOperationSpec);
    }
    /** @param options The options parameters. */
    getExpiredAccesses(options) {
        return this.sendOperationRequest({ options }, getExpiredAccessesOperationSpec);
    }
    /**
     * @param claimNumber
     * @param options The options parameters.
     */
    getCompetitionByClaimNumber(claimNumber, options) {
        return this.sendOperationRequest({ claimNumber, options }, getCompetitionByClaimNumberOperationSpec);
    }
    /** @param options The options parameters. */
    createSuccessHistory(options) {
        return this.sendOperationRequest({ options }, createSuccessHistoryOperationSpec);
    }
    /** @param options The options parameters. */
    getSuccessHistories(options) {
        return this.sendOperationRequest({ options }, getSuccessHistoriesOperationSpec);
    }
    /** @param options The options parameters. */
    getBudgetIdListByClaimNumberList(options) {
        return this.sendOperationRequest({ options }, getBudgetIdListByClaimNumberListOperationSpec);
    }
    /** @param options The options parameters. */
    getGenderListByClaimNumberList(options) {
        return this.sendOperationRequest({ options }, getGenderListByClaimNumberListOperationSpec);
    }
    /** @param options The options parameters. */
    getProviderListBySletatIdList(options) {
        return this.sendOperationRequest({ options }, getProviderListBySletatIdListOperationSpec);
    }
    /** @param options The options parameters. */
    getRegionListByClaimNumberList(options) {
        return this.sendOperationRequest({ options }, getRegionListByClaimNumberListOperationSpec);
    }
    /** @param options The options parameters. */
    getTouristChatInfo(options) {
        return this.sendOperationRequest({ options }, getTouristChatInfoOperationSpec);
    }
    /** @param options The options parameters. */
    getListOfTourGroups(options) {
        return this.sendOperationRequest({ options }, getListOfTourGroupsOperationSpec);
    }
    /** @param options The options parameters. */
    getTourGroupsWithUnconfirmedClaims(options) {
        return this.sendOperationRequest({ options }, getTourGroupsWithUnconfirmedClaimsOperationSpec);
    }
    /** @param options The options parameters. */
    getListOfTourGroupOccupation(options) {
        return this.sendOperationRequest({ options }, getListOfTourGroupOccupationOperationSpec);
    }
    /**
     * @param tourGroupId
     * @param options The options parameters.
     */
    getTourGroup(tourGroupId, options) {
        return this.sendOperationRequest({ tourGroupId, options }, getTourGroupOperationSpec);
    }
    /** @param options The options parameters. */
    updateTelegramLink(options) {
        return this.sendOperationRequest({ options }, updateTelegramLinkOperationSpec);
    }
    /**
     * @param tourGroupId
     * @param options The options parameters.
     */
    checkTourGroupReferenceAvailable(tourGroupId, options) {
        return this.sendOperationRequest({ tourGroupId, options }, checkTourGroupReferenceAvailableOperationSpec);
    }
    /**
     * @param groupId
     * @param options The options parameters.
     */
    syncTourDateTourBokingByGroup(groupId, options) {
        return this.sendOperationRequest({ groupId, options }, syncTourDateTourBokingByGroupOperationSpec);
    }
    /**
     * @param groupId
     * @param options The options parameters.
     */
    syncTourPriceTourBokingByGroup(groupId, options) {
        return this.sendOperationRequest({ groupId, options }, syncTourPriceTourBokingByGroupOperationSpec);
    }
    /**
     * @param tourGroupId
     * @param options The options parameters.
     */
    getTourGroupReferenceExcel(tourGroupId, options) {
        return this.sendOperationRequest({ tourGroupId, options }, getTourGroupReferenceExcelOperationSpec);
    }
    /**
     * Детальный просмотр тура
     * @param tourId
     * @param options The options parameters.
     */
    getSnbTour(tourId, options) {
        return this.sendOperationRequest({ tourId, options }, getSnbTourOperationSpec);
    }
    /**
     * Список туров
     * @param options The options parameters.
     */
    getSnbTours(options) {
        return this.sendOperationRequest({ options }, getSnbToursOperationSpec);
    }
    /**
     * Импорт новых туров из Strapi
     * @param options The options parameters.
     */
    importTours(options) {
        return this.sendOperationRequest({ options }, importToursOperationSpec);
    }
    /**
     * Тур. Фонд -> МО
     * @param tourId
     * @param options The options parameters.
     */
    exportTour(tourId, options) {
        return this.sendOperationRequest({ tourId, options }, exportTourOperationSpec);
    }
    /**
     * @param externalTourId
     * @param options The options parameters.
     */
    getTourByExternalId(externalTourId, options) {
        return this.sendOperationRequest({ externalTourId, options }, getTourByExternalIdOperationSpec);
    }
    /** @param options The options parameters. */
    getListOfTours(options) {
        return this.sendOperationRequest({ options }, getListOfToursOperationSpec);
    }
    /** @param options The options parameters. */
    createUser(options) {
        return this.sendOperationRequest({ options }, createUserOperationSpec);
    }
    /** @param options The options parameters. */
    requestResetPassword(options) {
        return this.sendOperationRequest({ options }, requestResetPasswordOperationSpec);
    }
    /** @param options The options parameters. */
    resetPassword(options) {
        return this.sendOperationRequest({ options }, resetPasswordOperationSpec);
    }
    /**
     * @param email
     * @param token
     * @param options The options parameters.
     */
    verifyUserToken(email, token, options) {
        return this.sendOperationRequest({ email, token, options }, verifyUserTokenOperationSpec);
    }
    /** @param options The options parameters. */
    setPasswordByUser(options) {
        return this.sendOperationRequest({ options }, setPasswordByUserOperationSpec);
    }
    /**
     * @param login
     * @param block
     * @param options The options parameters.
     */
    enableUser(login, block, options) {
        return this.sendOperationRequest({ login, block, options }, enableUserOperationSpec);
    }
    /** @param options The options parameters. */
    confirmUser(options) {
        return this.sendOperationRequest({ options }, confirmUserOperationSpec);
    }
    /** @param options The options parameters. */
    getBlockedUsers(options) {
        return this.sendOperationRequest({ options }, getBlockedUsersOperationSpec);
    }
    /** @param options The options parameters. */
    getUsers(options) {
        return this.sendOperationRequest({ options }, getUsersOperationSpec);
    }
    /** @param options The options parameters. */
    updateUser(options) {
        return this.sendOperationRequest({ options }, updateUserOperationSpec);
    }
    /**
     * @param login
     * @param options The options parameters.
     */
    getUserByLogin(login, options) {
        return this.sendOperationRequest({ login, options }, getUserByLoginOperationSpec);
    }
    /**
     * @param userId
     * @param options The options parameters.
     */
    getUserById(userId, options) {
        return this.sendOperationRequest({ userId, options }, getUserByIdOperationSpec);
    }
    /** @param options The options parameters. */
    getUsersWithRoles(options) {
        return this.sendOperationRequest({ options }, getUsersWithRolesOperationSpec);
    }
    /** @param options The options parameters. */
    getAdminUsers(options) {
        return this.sendOperationRequest({ options }, getAdminUsersOperationSpec);
    }
    /** @param options The options parameters. */
    linkUserAccessToProgramAndBudgetPost(options) {
        return this.sendOperationRequest({ options }, linkUserAccessToProgramAndBudgetPostOperationSpec);
    }
    /**
     * @param id
     * @param options The options parameters.
     */
    linkUserAccessToProgramAndBudgetPatch(id, options) {
        return this.sendOperationRequest({ id, options }, linkUserAccessToProgramAndBudgetPatchOperationSpec);
    }
}
// Operation Specifications
const serializer = coreClient.createSerializer(Mappers, /* isXml */ false);
const getApplicationsOperationSpec = {
    path: "/api/v2/applications",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getApplicationDetailsOperationSpec = {
    path: "/api/v2/applications/details",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationFullApiResponse
        }
    },
    queryParameters: [Parameters.applicationId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const importApplicationsOperationSpec = {
    path: "/api/v2/applications/import",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ImportedApplicationFileResultApiResponse
        },
        400: {
            bodyMapper: Mappers.ImportedApplicationFileResultApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    queryParameters: [Parameters.budgetId, Parameters.programId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const createApplicationsOperationSpec = {
    path: "/api/v2/applications/create",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationListApiResponse
        }
    },
    requestBody: Parameters.body,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const fillApplicationWithPassengersOperationSpec = {
    path: "/api/v2/applications/load/passengers",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationListApiResponse
        }
    },
    requestBody: Parameters.body1,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsBulkOperationSpec = {
    path: "/api/v2/applications/update/bulk",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    requestBody: Parameters.body2,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsBulkWithFileOperationSpec = {
    path: "/api/v2/applications/update/bulk/with-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const updateApplicationsFullOperationSpec = {
    path: "/api/v2/applications/update/full",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationFullListApiResponse
        }
    },
    requestBody: Parameters.body3,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsFullWithFileOperationSpec = {
    path: "/api/v2/applications/update/full/with-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationFullListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationFullListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationFullListApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const updateApplicationsStatusesOperationSpec = {
    path: "/api/v2/applications/update/statuses",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    requestBody: Parameters.body4,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsStatusesWithFileOperationSpec = {
    path: "/api/v2/applications/update/statuses/with-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const updateApplicationsPassengersOperationSpec = {
    path: "/api/v2/applications/update/passengers",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    requestBody: Parameters.body5,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsPassengersWithFileOperationSpec = {
    path: "/api/v2/applications/update/passengers/with-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const updateApplicationsPassengersAttendanceOperationSpec = {
    path: "/api/v2/applications/update/passengers/attendance",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    requestBody: Parameters.body5,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateApplicationsPassengersAttendanceWithFileOperationSpec = {
    path: "/api/v2/applications/update/passengers/attendance/with-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        },
        409: {
            bodyMapper: Mappers.ApplicationExtendedListApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const exchangeOperationSpec = {
    path: "/connect/token",
    httpMethod: "POST",
    responses: { 200: {} },
    urlParameters: [Parameters.$host],
    serializer
};
const blockReasonListOperationSpec = {
    path: "/api/blockreason/block-reason",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BlockReasonDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.BlockReasonDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const blockReasonPostOperationSpec = {
    path: "/api/blockreason/block-reason",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        }
    },
    requestBody: Parameters.body6,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const blockReasonPatchOperationSpec = {
    path: "/api/blockreason/block-reason/{id}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.BlockReasonDtoApiResponse
        }
    },
    requestBody: Parameters.body6,
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const budgetDetailedListOperationSpec = {
    path: "/api/budgets/budget/detailed",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const budgetPostOperationSpec = {
    path: "/api/budgets/budget",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        }
    },
    requestBody: Parameters.body7,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const budgetPatchOperationSpec = {
    path: "/api/budgets/budget/{id}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        }
    },
    requestBody: Parameters.body7,
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBudgetsOperationSpec = {
    path: "/api/budgets",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createBudgetsOperationSpec = {
    path: "/api/budgets",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        }
    },
    requestBody: Parameters.body8,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateBudgetOperationSpec = {
    path: "/api/budgets",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        }
    },
    requestBody: Parameters.body9,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBudgetListOperationSpec = {
    path: "/api/budgets/getbudgetlist",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getBudgetOperationSpec = {
    path: "/api/budgets/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept],
    serializer
};
const getBudgetByClaimNumberOperationSpec = {
    path: "/api/budgets/claim/{claimNumber}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetClaimDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetClaimDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.BudgetClaimDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept],
    serializer
};
const getCandidateDocumentsListOperationSpec = {
    path: "/api/candidatememberlistfiles/{listId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListFileICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListFileICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.listId],
    headerParameters: [Parameters.accept],
    serializer
};
const uploadCandidateDocumentsListOperationSpec = {
    path: "/api/candidatememberlistfiles/{listId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListFileICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListFileICollectionApiResponse
        }
    },
    formDataParameters: [Parameters.files],
    urlParameters: [Parameters.$host, Parameters.listId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getCandidateMemberListsOperationSpec = {
    path: "/api/candidatememberlists",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberItemListICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberItemListICollectionApiResponse
        }
    },
    queryParameters: [Parameters.includeCandidateMembers],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getListsbyCandidateDuplicatesOperationSpec = {
    path: "/api/candidatememberlists/bycandidateduplicates/{candidateId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.candidateId],
    headerParameters: [Parameters.accept],
    serializer
};
const getCandidateMemberListsByMemberIdOperationSpec = {
    path: "/api/candidatememberlists/bymemberid/{memberId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const getCandidateMemberListByIdOperationSpec = {
    path: "/api/candidatememberlists/{listId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberDetailsListApiResponse
        },
        404: {
            bodyMapper: Mappers.CandidateMemberDetailsListApiResponse
        }
    },
    queryParameters: [Parameters.includeDuplicates],
    urlParameters: [Parameters.$host, Parameters.listId],
    headerParameters: [Parameters.accept],
    serializer
};
const projectsOperationSpec = {
    path: "/api/candidatememberlists/projects/{listId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.Int64NullableListApiResponse
        },
        404: {
            bodyMapper: Mappers.Int64NullableListApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.listId],
    headerParameters: [Parameters.accept],
    serializer
};
const addMemberAccessesOperationSpec = {
    path: "/api/candidatememberlists/addmemberaccesses",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.Int64NullableListApiResponse
        },
        404: {
            bodyMapper: Mappers.Int64NullableListApiResponse
        }
    },
    queryParameters: [Parameters.candidateMemberId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const setCandidatesAsMembersOperationSpec = {
    path: "/api/candidatememberlists/setcandidatesasmembers",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    requestBody: Parameters.body10,
    queryParameters: [Parameters.withoutAccess],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const parseExcelOperationSpec = {
    path: "/api/candidatememberlists/parse",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListParseModelApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListParseModelApiResponse
        },
        404: {
            bodyMapper: Mappers.CandidateMemberListParseModelApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    queryParameters: [Parameters.isSpecialProject, Parameters.autoLoad],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const saveParsedCandidateMemberListOperationSpec = {
    path: "/api/candidatememberlists/save",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberListApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberListApiResponse
        }
    },
    requestBody: Parameters.body11,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getCandidateMembersOperationSpec = {
    path: "/api/candidatemembers",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createCandidateMemberOperationSpec = {
    path: "/api/candidatemembers",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        },
        201: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        },
        404: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        }
    },
    requestBody: Parameters.body12,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateCandidateMemberOperationSpec = {
    path: "/api/candidatemembers",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        }
    },
    requestBody: Parameters.body13,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getCandidateMemberByIdOperationSpec = {
    path: "/api/candidatemembers/{memberId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        },
        404: {
            bodyMapper: Mappers.CandidateMemberApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const notMembersOperationSpec = {
    path: "/api/candidatemembers/list/not-members",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CandidateMemberICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CandidateMemberICollectionApiResponse
        }
    },
    queryParameters: [Parameters.candidateMemberListId, Parameters.budgetId2],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const replaceCandidateEmailOperationSpec = {
    path: "/api/candidatemembers/replace-candidate-email",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.candidateId1, Parameters.email],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const linkCandidateToExistedMemberOperationSpec = {
    path: "/api/candidatemembers/link-candidate-to-existed-member",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [
        Parameters.withoutAccess,
        Parameters.candidateId1,
        Parameters.memberId1
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const acceptCertificatePaymentRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/acceptcertificatepaymentregistry",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.certificatePaymentRegistryId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setStatusCertificatePaymentRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/setstatuscertificatepaymentregistry",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.certificatePaymentRegistryId],
    queryParameters: [Parameters.certificatePaymentRegistryStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const deleteCertificatePaymentRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/deletecertificatepaymentregistry",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.certificatePaymentRegistryId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const dashboardCertificatePaymentRegistryOfGroupsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardcertificatepaymentregistryofgroups",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const dashboardReplacementCertificatePaymentRegistryOfGroupsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardreplacementcertificatepaymentregistryofgroups",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryItemListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getReplacementPaymentRegistryItemByGroupOperationSpec = {
    path: "/api/certificatepaymentregistries/getreplacementpaymentregistryitembygroup",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.PaymentRegistryClaimItemWithReplacingClaimsListApiResponse
        },
        404: {
            bodyMapper: Mappers.PaymentRegistryClaimItemWithReplacingClaimsListApiResponse
        }
    },
    requestBody: Parameters.body14,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getReplacementPaymentRegistryItemByItemIdsOperationSpec = {
    path: "/api/certificatepaymentregistries/getreplacementpaymentregistryitembyitemids",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.PaymentRegistryClaimItemWithReplacingClaimsListApiResponse
        }
    },
    requestBody: Parameters.body15,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const dashboardCertificatePaymentRegistryOfRegistryClaimsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardcertificatepaymentregistryofregistryclaims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryClaimItemIListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryClaimItemIListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryClaimItemIListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardCertificatePaymentRegistryClaimItemIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const dashboardReplacementCertificatePaymentRegistryOfRegistryClaimsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardreplacementcertificatepaymentregistryofregistryclaims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardReplacementCertificatePaymentRegistryClaimItemIListApiResponse
        }
    },
    requestBody: Parameters.body16,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const dashboardNoRegistryClaimsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardnoregistryclaims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardNoRegistryClaimItemIListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardNoRegistryClaimItemIListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardNoRegistryClaimItemIListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardNoRegistryClaimItemIListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const dashboardRegistryAnyClaimsOperationSpec = {
    path: "/api/certificatepaymentregistries/dashboardregistryanyclaims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardRegistryAnyClaimItemIListApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardRegistryAnyClaimItemIListApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardRegistryAnyClaimItemIListApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardRegistryAnyClaimItemIListApiResponse
        }
    },
    requestBody: Parameters.body17,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const parseRegistryExcelOperationSpec = {
    path: "/api/certificatepaymentregistries/parseregistryexcel",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CertificatePaymentRegistryDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.CertificatePaymentRegistryDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificatePaymentRegistryDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.CertificatePaymentRegistryDtoApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getCertificatePaymentRegistriesOperationSpec = {
    path: "/api/certificatepaymentregistries/getcertificatepaymentregistries",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificatePaymentRegistryListItemDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificatePaymentRegistryListItemDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getCertificatePaymentRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/getcertificatepaymentregistry/{certificatePaymentRegistryId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificatePaymentRegistryFullDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificatePaymentRegistryFullDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.certificatePaymentRegistryId1],
    headerParameters: [Parameters.accept],
    serializer
};
const getCertificatePaymentRegistryStatusesOperationSpec = {
    path: "/api/certificatepaymentregistries/getcertificatepaymentregistrystatuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const preCreateRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/precreateregistry",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.PreCreateRegistryItemDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.PreCreateRegistryItemDtoICollectionApiResponse
        }
    },
    queryParameters: [Parameters.groupId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getPaymentRegistryByGroupOperationSpec = {
    path: "/api/certificatepaymentregistries/payment-registry-generate-by-group",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    requestBody: Parameters.body18,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept2],
    mediaType: "json",
    serializer
};
const getPaymentRegistryByClaimsOperationSpec = {
    path: "/api/certificatepaymentregistries/payment-registry-generate-by-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    requestBody: Parameters.body19,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept2],
    mediaType: "json",
    serializer
};
const getCorrectingPaymentRegistryByItemsOperationSpec = {
    path: "/api/certificatepaymentregistries/correcting-payment-registry-generate-by-items",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    requestBody: Parameters.body20,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept3],
    mediaType: "json",
    serializer
};
const newRegistryOperationSpec = {
    path: "/api/certificatepaymentregistries/newregistry",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ApplicationRegistryLoadResultApiResponse
        },
        201: {
            bodyMapper: Mappers.ApplicationRegistryLoadResultApiResponse
        },
        400: {
            bodyMapper: Mappers.ApplicationRegistryLoadResultApiResponse
        }
    },
    formDataParameters: [
        Parameters.file1,
        Parameters.budgetId3,
        Parameters.registryType
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getCertificatesOperationSpec = {
    path: "/api/certificates",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createCertificateOperationSpec = {
    path: "/api/certificates",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        201: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        404: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    requestBody: Parameters.body21,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateCertificateOperationSpec = {
    path: "/api/certificates",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    requestBody: Parameters.body22,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getCertificateDetailedOperationSpec = {
    path: "/api/certificates/{id}/detailed",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.CertificateDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const getFreeCertificatesOperationSpec = {
    path: "/api/certificates/free",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.CertificateICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getCertificateByIdOperationSpec = {
    path: "/api/certificates/{certificateId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        404: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.certificateId],
    headerParameters: [Parameters.accept],
    serializer
};
const attachCertificateAndClaimToMemberAccessOperationSpec = {
    path: "/api/certificates/attach",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        201: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    requestBody: Parameters.body23,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const groupAttachCertificateAndClaimToMemberAccessOperationSpec = {
    path: "/api/certificates/group-attach",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.groupId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const detachCertificateAndClaimToMemberAccessOperationSpec = {
    path: "/api/certificates/detach",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        201: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    requestBody: Parameters.body24,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const transferCertificateAndClaimToMemberAccessOperationSpec = {
    path: "/api/certificates/transfer",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        201: {
            bodyMapper: Mappers.CertificateApiResponse
        },
        400: {
            bodyMapper: Mappers.CertificateApiResponse
        }
    },
    requestBody: Parameters.body25,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const claimDocumentsUploadOperationSpec = {
    path: "/api/claimdocuments/{claimId}/claim-documents-upload",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.formFiles,
        Parameters.changeStatus,
        Parameters.newStatus,
        Parameters.fileType
    ],
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const claimDocumentDeleteOperationSpec = {
    path: "/api/claimdocuments/claim-document-delete/{fileId}",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.fileId],
    headerParameters: [Parameters.accept],
    serializer
};
const claimResultDocumentsUploadOperationSpec = {
    path: "/api/claimdocuments/claim-result-documents-upload/{claimId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.tickets,
        Parameters.vouchers,
        Parameters.insurance,
        Parameters.accomodation,
        Parameters.absent,
        Parameters.explanatory,
        Parameters.compress
    ],
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const claimResultAdmDocumentsUploadOperationSpec = {
    path: "/api/claimdocuments/claim-result-adm-documents-upload/{claimId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.compress,
        Parameters.agencyReports,
        Parameters.claims,
        Parameters.acts,
        Parameters.registries,
        Parameters.bookingLists,
        Parameters.paymentOrders
    ],
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const resultClaimDocumentDirectUploadOperationSpec = {
    path: "/api/claimdocuments/{claimId}/result-claim-document-direct-upload",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.contentType2,
        Parameters.contentDisposition,
        Parameters.headers,
        Parameters.length,
        Parameters.name,
        Parameters.fileName
    ],
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const groupClaimsResultDocumentsUploadOperationSpec = {
    path: "/api/claimdocuments/group-claims-result-documents-upload/{groupNumber}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        403: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.tickets,
        Parameters.vouchers,
        Parameters.insurance,
        Parameters.accomodation,
        Parameters.absent,
        Parameters.explanatory,
        Parameters.compress
    ],
    urlParameters: [Parameters.$host, Parameters.groupNumber],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const groupClaimsDocumentsUploadOperationSpec = {
    path: "/group-claim-documents-upload/{groupId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        409: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    formDataParameters: [
        Parameters.formFiles,
        Parameters.changeStatus,
        Parameters.newStatus,
        Parameters.fileType
    ],
    urlParameters: [Parameters.$host, Parameters.groupId1],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setClaimDocumentStatusOperationSpec = {
    path: "/api/claimdocuments/{claimId}/document-status/{statusId}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        409: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId, Parameters.statusId],
    headerParameters: [Parameters.accept],
    serializer
};
const setGroupClaimDocumentStatusOperationSpec = {
    path: "/api/claimdocuments/group-document-status/{groupId}/{statusId}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        409: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.groupId1, Parameters.statusId],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimFilesOperationSpec = {
    path: "/api/claimdocuments/files/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimFileDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimFileDtoICollectionApiResponse
        },
        403: {
            bodyMapper: Mappers.ClaimFileDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimFileDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultClaimFilesArchiveOperationSpec = {
    path: "/api/claimdocuments/files/result-claim/download-archive/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept2],
    serializer
};
const getClaimsDocumentsArchiveOperationSpec = {
    path: "/api/claimdocuments/files/claim-documents-download-archive",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        400: {},
        404: {}
    },
    requestBody: Parameters.body26,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept2],
    mediaType: "json",
    serializer
};
const getClaimDocumentsArchiveOperationSpec = {
    path: "/api/claimdocuments/files/claim-document-download-archive",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        400: {},
        404: {}
    },
    queryParameters: [Parameters.claimId1, Parameters.fileName1],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept2],
    serializer
};
const getUnreadedClaimsOperationSpec = {
    path: "/api/claimmessages/get-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimsListForSpecifiedTourGroupOperationSpec = {
    path: "/api/claims/group/{tourGroupId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        }
    },
    queryParameters: [Parameters.claimState],
    urlParameters: [Parameters.$host, Parameters.tourGroupId],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimsListOperationSpec = {
    path: "/api/claims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDtoListApiResponse
        }
    },
    queryParameters: [Parameters.claimState],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimByIdOperationSpec = {
    path: "/api/claims/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourGroupMembersListExcelOperationSpec = {
    path: "/api/claims/confirmed/{externalTourGroupId}/export",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [Parameters.$host, Parameters.externalTourGroupId],
    headerParameters: [Parameters.accept3],
    serializer
};
const getClaimDocumentsSendingStatusesOperationSpec = {
    path: "/api/claims/document-sending-statuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DocumentsSendingStatusDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const setClaimStatusConfirmedOperationSpec = {
    path: "/api/claims/{claimId}/confirm",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const setClaimsListStatusConfirmedOperationSpec = {
    path: "/api/claims/confirm",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailedDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailedDtoListApiResponse
        }
    },
    requestBody: Parameters.body27,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const setClaimMemberAbsentOperationSpec = {
    path: "/api/claims/{claimId}/mark-absent",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const setClaimAmountsOperationSpec = {
    path: "/api/claims/{claimId}/change-amounts",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDetailedDtoApiResponse
        }
    },
    requestBody: Parameters.body28,
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getTourGroupClaimsListExcelOperationSpec = {
    path: "/api/claims/all/{externalTourGroupId}/export",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [Parameters.$host, Parameters.externalTourGroupId],
    headerParameters: [Parameters.accept3],
    serializer
};
const getClaimCountsOperationSpec = {
    path: "/api/claims/summary-claim-counts",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimSummaryCountsApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimSummaryCountsApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimSummaryCountsApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getFilteredClaimsOperationSpec = {
    path: "/api/claims/get-filtered-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getClientsOperationSpec = {
    path: "/api/clients",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse
        },
        201: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ClientAppICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createClientOperationSpec = {
    path: "/api/clients",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClientAppApiResponse
        },
        201: {
            bodyMapper: Mappers.ClientAppApiResponse
        },
        400: {
            bodyMapper: Mappers.ClientAppApiResponse
        }
    },
    requestBody: Parameters.body30,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const deleteClientOperationSpec = {
    path: "/api/clients/{clientId}",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.clientId],
    headerParameters: [Parameters.accept],
    serializer
};
const getCompetitionsOperationSpec = {
    path: "/api/competitions",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.CompetitionDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.CompetitionDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.CompetitionDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createCompetitionOperationSpec = {
    path: "/api/competitions",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        500: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        }
    },
    requestBody: Parameters.body31,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateCompetitionOperationSpec = {
    path: "/api/competitions",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        },
        500: {
            bodyMapper: Mappers.CompetitionDtoApiResponse
        }
    },
    requestBody: Parameters.body32,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getClaimMessagesOperationSpec = {
    path: "/api/conversations/messages/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId2],
    headerParameters: [Parameters.accept],
    serializer
};
const getClaimMessageOperationSpec = {
    path: "/api/conversations/messages/single/{messageId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.messageId],
    headerParameters: [Parameters.accept],
    serializer
};
const createClaimMessageOperationSpec = {
    path: "/api/conversations/messages",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimMessageDtoApiResponse
        }
    },
    requestBody: Parameters.body33,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const markMessagesAsReadOperationSpec = {
    path: "/api/conversations/mark-as-read",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    requestBody: Parameters.body27,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const setMessageStatusOperationSpec = {
    path: "/api/conversations/set-message-status",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.messageId1, Parameters.statusId1],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const uploadMessageFileOperationSpec = {
    path: "/api/conversations/upload-mesage-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourBookingFileDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.TourBookingFileDtoApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const dashboardStatisticsOfGroupsOperationSpec = {
    path: "/api/dashboard",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.DashboardListItemPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.DashboardListItemPaginateApiResponse
        },
        403: {
            bodyMapper: Mappers.DashboardListItemPaginateApiResponse
        },
        404: {
            bodyMapper: Mappers.DashboardListItemPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getTourDetailsPdfOperationSpec = {
    path: "/api/dashboard/{externalTourId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [Parameters.$host, Parameters.externalTourId],
    headerParameters: [Parameters.accept4],
    serializer
};
const getFilteredMemberStatisticsOperationSpec = {
    path: "/api/dashboard/filtered/members",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberStatisticsItemDtoPaginateApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberStatisticsItemDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberStatisticsItemDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getMemberStatisticsOperationSpec = {
    path: "/api/dashboard/members",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MembersStatisticsItemICollectionApiResponse
        },
        201: {
            bodyMapper: Mappers.MembersStatisticsItemICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.MembersStatisticsItemICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createInfoMessageOperationSpec = {
    path: "/api/directumintegration/create",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        201: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    requestBody: Parameters.body34,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getInfoMessageByClaimNumberOperationSpec = {
    path: "/api/directumintegration/infomessages/getbyclaimnumber/{claimNumber}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.InfoMessageContractApiResponse
        },
        201: {
            bodyMapper: Mappers.InfoMessageContractApiResponse
        },
        400: {
            bodyMapper: Mappers.InfoMessageContractApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept],
    serializer
};
const rejectInfoMessageOperationSpec = {
    path: "/api/directumintegration/reject",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        201: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        404: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    requestBody: Parameters.body35,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getInfoMessagesOperationSpec = {
    path: "/api/directumintegration/get-info-messages",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.InfoMessageDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getStatusByClaimNumberOperationSpec = {
    path: "/api/directumintegration/statuses/by-claim-number/{claimNumber}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.InfoMessageStatusDto
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept],
    serializer
};
const getInfoMessageRequestsOperationSpec = {
    path: "/api/directumintegration/requests",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestApiModel"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestApiModel"
                        }
                    }
                }
            }
        }
    },
    queryParameters: [Parameters.status],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.accIntegration],
    serializer
};
const getInfoMessageRequestStatusesOperationSpec = {
    path: "/api/directumintegration/statuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestStatusApiModel"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestStatusApiModel"
                        }
                    }
                }
            }
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.accIntegration],
    serializer
};
const setInfoMessageRequestStatusOperationSpec = {
    path: "/api/directumintegration/setinfomessagerequeststatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestApiModel"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "DirectumInfoMessageRequestApiModel"
                        }
                    }
                }
            }
        }
    },
    requestBody: Parameters.body36,
    urlParameters: [Parameters.$host],
    headerParameters: [
        Parameters.accept,
        Parameters.contentType1,
        Parameters.accIntegration
    ],
    mediaType: "json",
    serializer
};
const getFileOperationSpec = {
    path: "/api/files",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    queryParameters: [Parameters.traceId, Parameters.fileId1],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept2],
    serializer
};
const postFileOperationSpec = {
    path: "/api/files",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.File
        },
        201: {
            bodyMapper: Mappers.File
        },
        400: {
            bodyMapper: Mappers.File
        }
    },
    formDataParameters: [Parameters.traceId1, Parameters.file2],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getExpiringMemberAccessesOperationSpec = {
    path: "/api/member-accesses/list/{budgetId}/expiring",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ExpiringMemberAccessListApiResponse
        },
        400: {
            bodyMapper: Mappers.ExpiringMemberAccessListApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept],
    serializer
};
const updateParticipationCertificateOperationSpec = {
    path: "/api/member-accesses/update/participationcertificate",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        409: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    requestBody: Parameters.body37,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getParticpationCertificatesOperationSpec = {
    path: "/api/member-accesses/participation-certificates",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ParticipationCertificateDtoPaginateApiResponse
        },
        201: {
            bodyMapper: Mappers.ParticipationCertificateDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.ParticipationCertificateDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getMemberAccessByIdOperationSpec = {
    path: "/api/member-accesses/byid/{accessId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.accessId],
    headerParameters: [Parameters.accept],
    serializer
};
const extendMemberAccessOperationSpec = {
    path: "/api/member-accesses/extend/{accessId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        },
        409: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        }
    },
    requestBody: Parameters.body38,
    urlParameters: [Parameters.$host, Parameters.accessId1],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const blockMemberAccessOperationSpec = {
    path: "/api/member-accesses/block",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        }
    },
    requestBody: Parameters.body39,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const createMemberAccessOperationSpec = {
    path: "/api/member-accesses/create",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberAccessDtoApiResponse
        }
    },
    requestBody: Parameters.body40,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getMemberAccessListOperationSpec = {
    path: "/api/member-accesses/getmemberaccesslist",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessInfoItemDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberAccessInfoItemDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getFilteredMemberAccessesOperationSpec = {
    path: "/api/member-accesses/get-filtered-member-accesses",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessInfoItemDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberAccessInfoItemDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getMemberListOperationSpec = {
    path: "/api/members",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberICollectionApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getFilteredMembersOperationSpec = {
    path: "/api/members/filtered",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.MemberPaginateApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getInvitedMemberListOperationSpec = {
    path: "/api/members/invited",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.InvitedMemberDtoPaginateApiResponse
        },
        201: {
            bodyMapper: Mappers.InvitedMemberDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.InvitedMemberDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getMemberOperationSpec = {
    path: "/api/members/{memberId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const getMemberCardOperationSpec = {
    path: "/api/members/card/{memberId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberCardDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberCardDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberCardDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberCardDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const getMemberWithProviderCheckOperationSpec = {
    path: "/api/members/{memberId}/with-check",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const getMemberWithAccessesOperationSpec = {
    path: "/api/members/{memberId}/access-granted",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberApiResponse
        },
        201: {
            bodyMapper: Mappers.MemberApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const getMemberChangeDataRequestListOperationSpec = {
    path: "/api/members/{memberId}/edit-request",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoICollectionApiResponse
        },
        201: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const postMemberChangeDataRequestOperationSpec = {
    path: "/api/members/edit-request",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        }
    },
    requestBody: Parameters.body41,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const attachFileToMemberProfileChangeDataRequestOperationSpec = {
    path: "/api/members/edit-request/{requestId}/attach-file",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const approveMemberChangeDataRequestOperationSpec = {
    path: "/api/members/edit-request/{requestId}/approve",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const rejectMemberChangeDataRequestOperationSpec = {
    path: "/api/members/edit-request/{requestId}/reject",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberDataResponseDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId],
    headerParameters: [Parameters.accept],
    serializer
};
const generateFastLoginLinkForMemberOperationSpec = {
    path: "/api/members/{memberId}/fast-login-link",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.FastLoginLinkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.FastLoginLinkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.FastLoginLinkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const checkMemberPasswordIsSetOperationSpec = {
    path: "/api/members/{memberId}/account-check",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberPasswordResultResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberPasswordResultResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberPasswordResultResponseDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept],
    serializer
};
const changeMemberEmailOperationSpec = {
    path: "/api/members/{memberId}/change-email",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ChangeMemberEmailResultResponseDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ChangeMemberEmailResultResponseDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ChangeMemberEmailResultResponseDtoApiResponse
        }
    },
    requestBody: Parameters.body42,
    urlParameters: [Parameters.$host, Parameters.memberId],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBookingPapersByGroupIdOperationSpec = {
    path: "/api/paper/booking-paper-by-group/{groupId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    queryParameters: [
        Parameters.claimState,
        Parameters.withStamp,
        Parameters.isPdf
    ],
    urlParameters: [Parameters.$host, Parameters.groupId1],
    headerParameters: [Parameters.accept2],
    serializer
};
const getBookingPaperOperationSpec = {
    path: "/api/paper/booking-paper/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        },
        404: {}
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept3],
    serializer
};
const prelimitaryAgentReportDatePeriodsOperationSpec = {
    path: "/api/paper/prelimitary-agent-report/date-periods",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.DateTimeListApiResponse
        },
        400: {
            bodyMapper: Mappers.DateTimeListApiResponse
        }
    },
    queryParameters: [Parameters.dayIncludePeriod],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const providersOfTourGroupsFinishedAtSpecificMonthOperationSpec = {
    path: "/api/paper/agent-report/{periodDate}/providers",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.periodDate],
    headerParameters: [Parameters.accept],
    serializer
};
const getPreliminaryAgentReportExcelOperationSpec = {
    path: "/api/paper/prelimitary-agent-report/{periodDate}/providers/{providerId}/export",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [
        Parameters.$host,
        Parameters.periodDate,
        Parameters.providerId
    ],
    headerParameters: [Parameters.accept3],
    serializer
};
const getFinalAgentReportExcelOperationSpec = {
    path: "/api/paper/final-agent-report/{periodDate}/providers/{providerId}/export",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [
        Parameters.$host,
        Parameters.periodDate,
        Parameters.providerId
    ],
    headerParameters: [Parameters.accept3],
    serializer
};
const getInfoMessagesLogbookOperationSpec = {
    path: "/api/paper/infomessages/logbook",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept3],
    serializer
};
const getListOfAgentReportStatusesOperationSpec = {
    path: "/api/paper/agent-report-statuses/{periodDate}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.AgentReportDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.AgentReportDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.AgentReportDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.periodDate],
    headerParameters: [Parameters.accept],
    serializer
};
const createAgentReportOperationSpec = {
    path: "/api/paper/agent-report-statuses/{periodDate}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        }
    },
    requestBody: Parameters.body43,
    urlParameters: [Parameters.$host, Parameters.periodDate],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const setAgentReportSignedOperationSpec = {
    path: "/api/paper/agent-report-statuses/{agentReportId}",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.agentReportId],
    headerParameters: [Parameters.accept],
    serializer
};
const getListOfReservationDeptStatusesOperationSpec = {
    path: "/api/paper/agent-report-statuses/reservation-dept-statuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ReservationDeptStatusDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ReservationDeptStatusDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.ReservationDeptStatusDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const setAgentReportReservationStatusOperationSpec = {
    path: "/api/paper/agent-report-statuses/{agentReportId}/reservation-dept",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.AgentReportDtoApiResponse
        }
    },
    requestBody: Parameters.body44,
    urlParameters: [Parameters.$host, Parameters.agentReportId],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const informationNoticeGetOperationSpec = {
    path: "/api/paper/information-notice/{requestId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        400: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        403: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        404: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId1],
    headerParameters: [Parameters.accept],
    serializer
};
const informationNoticeUpdateOperationSpec = {
    path: "/api/paper/information-notice/{requestId}/update",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        400: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        403: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        404: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        }
    },
    requestBody: Parameters.body45,
    urlParameters: [Parameters.$host, Parameters.requestId1],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const informationNoticeSendOperationSpec = {
    path: "/api/paper/information-notice/{requestId}/send",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        400: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        403: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        404: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        }
    },
    requestBody: Parameters.body45,
    urlParameters: [Parameters.$host, Parameters.requestId1],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const informationNoticeResendOperationSpec = {
    path: "/api/paper/information-notice/{requestId}/resend",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        403: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        404: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.requestId1],
    headerParameters: [Parameters.accept],
    serializer
};
const informationNoticeDocumentCreateOperationSpec = {
    path: "/api/paper/information-notice/document/create",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    requestBody: Parameters.body45,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept5],
    mediaType: "json",
    serializer
};
const informationNoticeDocumentCreateThumbnailOperationSpec = {
    path: "/api/paper/information-notice/document/create/thumbnail/width:{width}/height:{height}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    requestBody: Parameters.body45,
    urlParameters: [Parameters.$host, Parameters.width, Parameters.height],
    headerParameters: [Parameters.contentType1, Parameters.accept6],
    mediaType: "json",
    serializer
};
const informationNoticeUploadDocumentOperationSpec = {
    path: "/api/paper/information-notice/document/upload/thumbnail/width:{width}/height:{height}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host, Parameters.width, Parameters.height],
    headerParameters: [Parameters.contentType, Parameters.accept7],
    serializer
};
const informationNoticeArtifactSendOperationSpec = {
    path: "/api/paper/information-notice/{requestId}/send/artifact",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        400: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        403: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        },
        404: {
            bodyMapper: Mappers.InformationMessageInputApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host, Parameters.requestId1],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getProgramListOperationSpec = {
    path: "/api/programs/getprogramlist",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProgramDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ProgramDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const programPostOperationSpec = {
    path: "/api/programs/program",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        }
    },
    requestBody: Parameters.body46,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getProgramOperationSpec = {
    path: "/api/programs/program/{id}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const programPatchOperationSpec = {
    path: "/api/programs/program/{id}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ProgramDtoApiResponse
        }
    },
    requestBody: Parameters.body46,
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getProgramsByProviderOperationSpec = {
    path: "/api/programs/getprogramsbyprovider",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserToBudgetByProviderDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.UserToBudgetByProviderDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getProgramsByProviderIdOperationSpec = {
    path: "/api/programs/getprogramsbyproviderid",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.BudgetsByProviderDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.BudgetsByProviderDtoICollectionApiResponse
        }
    },
    queryParameters: [Parameters.providerId1],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getProjectsOperationSpec = {
    path: "/api/projects",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ProjectDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createProjectOperationSpec = {
    path: "/api/projects",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        }
    },
    requestBody: Parameters.body47,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const updateProjectOperationSpec = {
    path: "/api/projects",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        }
    },
    requestBody: Parameters.body48,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getProjectOperationSpec = {
    path: "/api/projects/{ProjectId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ProjectDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.projectId],
    headerParameters: [Parameters.accept],
    serializer
};
const removeProjectOperationSpec = {
    path: "/api/projects/{projectId}",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: {
                type: { name: "Dictionary", value: { type: { name: "any" } } }
            }
        },
        204: {
            bodyMapper: {
                type: { name: "Dictionary", value: { type: { name: "any" } } }
            }
        },
        400: {
            bodyMapper: {
                type: { name: "Dictionary", value: { type: { name: "any" } } }
            }
        }
    },
    urlParameters: [Parameters.$host, Parameters.projectId1],
    headerParameters: [Parameters.accept],
    serializer
};
const linkUserAccessToProviderOperationSpec = {
    path: "/api/provideraccess/user-provider-access/{providerId}/{userId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.UserToProviderAccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.UserToProviderAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.UserToProviderAccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.UserToProviderAccessDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.providerId, Parameters.userId],
    headerParameters: [Parameters.accept],
    serializer
};
const dropUserAccessToProviderOperationSpec = {
    path: "/api/provideraccess/user-provider-access/{providerId}/{userId}",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.providerId, Parameters.userId],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourOperatorUsersOperationSpec = {
    path: "/api/provideraccess/user-provider-access/usersbyoperator/{providerId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        201: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        404: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.providerId],
    headerParameters: [Parameters.accept],
    serializer
};
const linkUserAccessToMultipleProvidersOperationSpec = {
    path: "/api/provideraccess/user-provider-access",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.UserToProviderAccessDtoICollectionApiResponse
        },
        201: {
            bodyMapper: Mappers.UserToProviderAccessDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.UserToProviderAccessDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.UserToProviderAccessDtoICollectionApiResponse
        }
    },
    queryParameters: [Parameters.userId1, Parameters.providerIds],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const dropUserAccessToProvidersOperationSpec = {
    path: "/api/provideraccess/user-provider-access",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.userId1, Parameters.providerIds],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const usersWithProviderAccessOperationSpec = {
    path: "/api/provideraccess/users-with-provider-access",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getUserProvidersAccessesOperationSpec = {
    path: "/api/provideraccess/users-providers-accesses/{userId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.UserWithProviderAccessDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.userId2],
    headerParameters: [Parameters.accept],
    serializer
};
const getProviderByIdOperationSpec = {
    path: "/api/providers/getproviderbyid",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderInfoDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderInfoDtoApiResponse
        }
    },
    queryParameters: [Parameters.providerId1],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const providerListOperationSpec = {
    path: "/api/providers/provider",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.ProviderDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const providerPostOperationSpec = {
    path: "/api/providers/provider",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        }
    },
    requestBody: Parameters.body49,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const providerGetOperationSpec = {
    path: "/api/providers/provider/{id}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept],
    serializer
};
const providerPatchOperationSpec = {
    path: "/api/providers/provider/{id}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ProviderDtoApiResponse
        }
    },
    requestBody: Parameters.body49,
    urlParameters: [Parameters.$host, Parameters.id],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const providerLinkToBudgetOperationSpec = {
    path: "/api/providers/provider/{providerId}/link-budget/{budgetId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ProviderToBudgetDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ProviderToBudgetDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ProviderToBudgetDtoApiResponse
        }
    },
    urlParameters: [
        Parameters.$host,
        Parameters.budgetId1,
        Parameters.providerId
    ],
    headerParameters: [Parameters.accept],
    serializer
};
const getFilteredResultClaimsOperationSpec = {
    path: "/api/resultclaims/get-filtered-result-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultClaimDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getStatisticsByOperatorsResultClaimsOperationSpec = {
    path: "/api/resultclaims/get-statistics-by-operators-result-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.StatisticsByOperatorResultClaimDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.StatisticsByOperatorResultClaimDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getStatisticsResultClaimsOperationSpec = {
    path: "/api/resultclaims/get-statistics-result-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.StatisticsResultClaimDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.StatisticsResultClaimDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultClaimTourOperatorStatusesOperationSpec = {
    path: "/api/resultclaims/getresultclaimtouroperatorstatuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultClaimAdministratorStatusesOperationSpec = {
    path: "/api/resultclaims/getresultclaimadministratorstatuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultClaimCheckStatusesOperationSpec = {
    path: "/api/resultclaims/getresultclaimcheckstatuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ByteKeyValueDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const setResultClaimTourOperatorStatusOperationSpec = {
    path: "/api/resultclaims/setresultclaimtouroperatorstatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.claimId3],
    queryParameters: [Parameters.resultClaimTourOperatorStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setResultClaimAdministratorStatusOperationSpec = {
    path: "/api/resultclaims/setresultclaimadministratorstatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.claimId3],
    queryParameters: [Parameters.resultClaimAdministratorStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setGroupResultClaimTourOperatorStatusOperationSpec = {
    path: "/api/resultclaims/setgroupresultclaimtouroperatorstatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.group],
    queryParameters: [Parameters.resultClaimTourOperatorStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setGroupResultClaimAdministratorStatusOperationSpec = {
    path: "/api/resultclaims/setgroupresultclaimadministratorstatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.group],
    queryParameters: [Parameters.resultClaimAdministratorStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const setGroupResultClaimResultCheckStatusOperationSpec = {
    path: "/api/resultclaims/setgroupresultclaimresultcheckstatus",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.GuidSuccessDtoApiResponse
        }
    },
    formDataParameters: [Parameters.group],
    queryParameters: [Parameters.checkStatusId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getResultClaimByIdOperationSpec = {
    path: "/api/resultclaims/{claimId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimId],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultClaimByNumberOperationSpec = {
    path: "/api/resultclaims/by-number/{claimNumber}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept],
    serializer
};
const resultClaimSetCheckStatusOperationSpec = {
    path: "/api/resultclaims/{claimNumber}/check-status",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.ResultClaimDetailedDtoApiResponse
        }
    },
    requestBody: Parameters.body50,
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBookingCenterClaimCheckStatusesOperationSpec = {
    path: "/api/resultclaims/resultclaim-check-statuses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimCheckStatusDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimCheckStatusDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const resultClaimDocumentDownloadOperationSpec = {
    path: "/api/resultclaims/document-download",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    requestBody: Parameters.body51,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType1, Parameters.accept6],
    mediaType: "json",
    serializer
};
const setResponsibleClaimOperationSpec = {
    path: "/api/resultclaims/setresponsibleclaim",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        403: {
            bodyMapper: {
                type: { name: "Dictionary", value: { type: { name: "any" } } }
            }
        }
    },
    requestBody: Parameters.body52,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const setCommentClaimOperationSpec = {
    path: "/api/resultclaims/setcommentclaim",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ResultClaimDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        403: {
            bodyMapper: {
                type: { name: "Dictionary", value: { type: { name: "any" } } }
            }
        }
    },
    requestBody: Parameters.body53,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getResultGroupsOperationSpec = {
    path: "/api/resultgroups/get-result-groups",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ResultGroupDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultGroupDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getResultGroupByIdOperationSpec = {
    path: "/api/resultgroups/get-result-groups/{resultGroupId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ResultGroupDetailedDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.ResultGroupDetailedDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.resultGroupId],
    headerParameters: [Parameters.accept],
    serializer
};
const updateResultGroupOperationSpec = {
    path: "/api/resultgroups/update-result-groups",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        404: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    requestBody: Parameters.body54,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const uploadConfirmationMailOperationSpec = {
    path: "/api/resultgroups/upload-confirmation-mail",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.StringApiResponse
        },
        400: {
            bodyMapper: Mappers.StringApiResponse
        },
        404: {
            bodyMapper: Mappers.StringApiResponse
        }
    },
    formDataParameters: [Parameters.file],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.contentType, Parameters.accept1],
    serializer
};
const getRolesOperationSpec = {
    path: "/api/roles",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.StringListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createRoleOperationSpec = {
    path: "/api/roles",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        201: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.roleName],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const deleteRoleOperationSpec = {
    path: "/api/roles",
    httpMethod: "DELETE",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.roleName],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const rsvProjectsOperationSpec = {
    path: "/redcross-api/projects/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "ProjectRsvApiDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "ProjectRsvApiDto" }
                    }
                }
            }
        }
    },
    queryParameters: [
        Parameters.fromLastTransferDate,
        Parameters.limit,
        Parameters.autoConfirmTransfer
    ],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvCandidateListsOperationSpec = {
    path: "/redcross-api/candidatelists/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberListRsvApiDto"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "CandidateMemberListRsvApiDto"
                        }
                    }
                }
            }
        }
    },
    queryParameters: [Parameters.projectId2],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvCandidatesOperationSpec = {
    path: "/redcross-api/candidates/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "CandidateMemberRsvApiDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "CandidateMemberRsvApiDto" }
                    }
                }
            }
        }
    },
    queryParameters: [
        Parameters.candidateId1,
        Parameters.memberId1,
        Parameters.fromLastTransferDate,
        Parameters.limit,
        Parameters.autoConfirmTransfer,
        Parameters.projectId2,
        Parameters.candidateMemberListId1
    ],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration],
    serializer
};
const acceptHashedRecordsOperationSpec = {
    path: "/redcross-api/accept-hashed-records",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "AcceptHashRecordRsvApiDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "AcceptHashRecordRsvApiDto" }
                    }
                }
            }
        }
    },
    requestBody: Parameters.body55,
    urlParameters: [Parameters.$host],
    headerParameters: [
        Parameters.accept,
        Parameters.contentType1,
        Parameters.accIntegration
    ],
    mediaType: "json",
    serializer
};
const rsvMembersOperationSpec = {
    path: "/redcross-api/members/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: { type: { name: "Composite", className: "MemberRsvApiDto" } }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: { type: { name: "Composite", className: "MemberRsvApiDto" } }
                }
            }
        }
    },
    queryParameters: [
        Parameters.candidateId1,
        Parameters.memberId1,
        Parameters.fromLastTransferDate,
        Parameters.limit,
        Parameters.autoConfirmTransfer,
        Parameters.candidateMemberListId1
    ],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration],
    serializer
};
const rsvMembersCandidatesOperationSpec = {
    path: "/redcross-api/members-candidates/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberRsvWithCandidatesRsvApiDto"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "MemberRsvWithCandidatesRsvApiDto"
                        }
                    }
                }
            }
        }
    },
    queryParameters: [Parameters.memberId1],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvCertificatesOperationSpec = {
    path: "/redcross-api/certificates/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "CertificateRsvApiDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "CertificateRsvApiDto" }
                    }
                }
            }
        }
    },
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvPaymentRegistriesOperationSpec = {
    path: "/redcross-api/payment-registries/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "PaymentRegistryRsvApiDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "PaymentRegistryRsvApiDto" }
                    }
                }
            }
        }
    },
    queryParameters: [
        Parameters.fromLastTransferDate,
        Parameters.limit,
        Parameters.autoConfirmTransfer
    ],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvPaymentRegistryOperationSpec = {
    path: "/redcross-api/payment-registry/{budgetId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PaymentRegistryWithItemsRsvApiDto"
                        }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: {
                            name: "Composite",
                            className: "PaymentRegistryWithItemsRsvApiDto"
                        }
                    }
                }
            }
        }
    },
    queryParameters: [Parameters.paymentRegistryId],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [Parameters.accept, Parameters.accIntegration1],
    serializer
};
const rsvPaymentRegistrySetStatusOperationSpec = {
    path: "/redcross-api/payment-registry-set-status/{budgetId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.PaymentRegistryRsvApiDto
        },
        400: {
            bodyMapper: Mappers.PaymentRegistryRsvApiDto
        }
    },
    requestBody: Parameters.body56,
    queryParameters: [Parameters.statusPaymentRegistry],
    urlParameters: [Parameters.$host, Parameters.budgetId1],
    headerParameters: [
        Parameters.accept,
        Parameters.contentType1,
        Parameters.accIntegration1
    ],
    mediaType: "json",
    serializer
};
const getRsvResultListOperationSpec = {
    path: "/api/rsvresults/list",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.RsvResultDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const searchTourOrTourGroupByIdOperationSpec = {
    path: "/api/search/tours-groups",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ToursSearchVmApiResponse
        },
        400: {
            bodyMapper: Mappers.ToursSearchVmApiResponse
        },
        403: {
            bodyMapper: Mappers.ToursSearchVmApiResponse
        }
    },
    queryParameters: [Parameters.query, Parameters.mode],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const searchMembersOperationSpec = {
    path: "/api/search/members",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberSearchVmApiResponse
        },
        400: {
            bodyMapper: Mappers.MemberSearchVmApiResponse
        },
        403: {
            bodyMapper: Mappers.MemberSearchVmApiResponse
        },
        404: {
            bodyMapper: Mappers.MemberSearchVmApiResponse
        }
    },
    queryParameters: [Parameters.query, Parameters.mode],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const searchClaimsOperationSpec = {
    path: "/api/search/claims",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimSearchVmApiResponse
        },
        400: {
            bodyMapper: Mappers.ClaimSearchVmApiResponse
        },
        403: {
            bodyMapper: Mappers.ClaimSearchVmApiResponse
        },
        404: {
            bodyMapper: Mappers.ClaimSearchVmApiResponse
        }
    },
    queryParameters: [Parameters.query, Parameters.mode],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getAccessTokensByUserOperationSpec = {
    path: "/api/sletatintegration/getaccesstokensbyuser",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "MemberAccessSletatDto" }
                    }
                }
            }
        },
        400: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "MemberAccessSletatDto" }
                    }
                }
            }
        }
    },
    queryParameters: [Parameters.userId3],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getAccessTokenOperationSpec = {
    path: "/api/sletatintegration/getaccesstoken",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessSletatDto
        },
        400: {
            bodyMapper: Mappers.MemberAccessSletatDto
        }
    },
    queryParameters: [Parameters.accessTokenId],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const extendAccessOperationSpec = {
    path: "/api/sletatintegration/extend-access/{accessTokenId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.MemberAccessSletatDto
        },
        400: {
            bodyMapper: Mappers.MemberAccessSletatDto
        }
    },
    urlParameters: [Parameters.$host, Parameters.accessTokenId1],
    headerParameters: [Parameters.accept],
    serializer
};
const getExpiredAccessesOperationSpec = {
    path: "/api/sletatintegration/get-expired-accesses",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: {
                type: {
                    name: "Sequence",
                    element: {
                        type: { name: "Composite", className: "MemberAccessSletatDto" }
                    }
                }
            }
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getCompetitionByClaimNumberOperationSpec = {
    path: "/api/sletatintegration/competitions/{claimNumber}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.ClaimCompetitionDto
        },
        400: {
            bodyMapper: Mappers.ClaimCompetitionDto
        },
        404: {
            bodyMapper: Mappers.ClaimCompetitionDto
        }
    },
    urlParameters: [Parameters.$host, Parameters.claimNumber],
    headerParameters: [Parameters.accept],
    serializer
};
const createSuccessHistoryOperationSpec = {
    path: "/api/successhistory/createorupdate",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        201: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        404: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    requestBody: Parameters.body57,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getSuccessHistoriesOperationSpec = {
    path: "/api/successhistory/list",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SuccessHistoryDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBudgetIdListByClaimNumberListOperationSpec = {
    path: "/api/sync/bulk/claim-budget",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SyncBudgetDtoListApiResponse
        }
    },
    requestBody: Parameters.body58,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getGenderListByClaimNumberListOperationSpec = {
    path: "/api/sync/bulk/claim-gender",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SyncGenderDtoListApiResponse
        }
    },
    requestBody: Parameters.body58,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getProviderListBySletatIdListOperationSpec = {
    path: "/api/sync/bulk/claim-provider",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SyncProviderDtoListApiResponse
        }
    },
    requestBody: Parameters.body10,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getRegionListByClaimNumberListOperationSpec = {
    path: "/api/sync/bulk/claim-tourgroup-region",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SyncTourRegionDtoListApiResponse
        }
    },
    requestBody: Parameters.body58,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getTouristChatInfoOperationSpec = {
    path: "/api/telegramintegration/tourist-chat-info",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TouristChatInfoApiModelApiResponse
        },
        403: {
            bodyMapper: Mappers.TouristChatInfoApiModelApiResponse
        }
    },
    queryParameters: [Parameters.phone],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.authorization],
    serializer
};
const getListOfTourGroupsOperationSpec = {
    path: "/api/tourgroups",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupDtoICollectionApiResponse
        },
        403: {
            bodyMapper: Mappers.TourGroupDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.TourGroupDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourGroupsWithUnconfirmedClaimsOperationSpec = {
    path: "/api/tourgroups/get-with-unconfirmed-claims",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupDtoPaginateApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupDtoPaginateApiResponse
        },
        403: {
            bodyMapper: Mappers.TourGroupDtoPaginateApiResponse
        }
    },
    requestBody: Parameters.body29,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getListOfTourGroupOccupationOperationSpec = {
    path: "/api/tourgroups/occupation",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupOccupationDtoICollectionApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupOccupationDtoICollectionApiResponse
        },
        403: {
            bodyMapper: Mappers.TourGroupOccupationDtoICollectionApiResponse
        },
        404: {
            bodyMapper: Mappers.TourGroupOccupationDtoICollectionApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourGroupOperationSpec = {
    path: "/api/tourgroups/{tourGroupId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.tourGroupId],
    headerParameters: [Parameters.accept],
    serializer
};
const updateTelegramLinkOperationSpec = {
    path: "/api/tourgroups/updatetelegramlink",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        404: {
            bodyMapper: Mappers.ObjectApiResponse
        }
    },
    requestBody: Parameters.body59,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const checkTourGroupReferenceAvailableOperationSpec = {
    path: "/api/tourgroups/{tourGroupId}/export-reference/available",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.TourGroupDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.tourGroupId],
    headerParameters: [Parameters.accept],
    serializer
};
const syncTourDateTourBokingByGroupOperationSpec = {
    path: "/api/tourgroups/sync-tourdate-tourboking-by-group/{groupId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupFlatDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupFlatDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.groupId2],
    headerParameters: [Parameters.accept],
    serializer
};
const syncTourPriceTourBokingByGroupOperationSpec = {
    path: "/api/tourgroups/sync-tourprice-tourboking-by-group/{groupId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.TourGroupFlatDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.TourGroupFlatDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.groupId2],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourGroupReferenceExcelOperationSpec = {
    path: "/api/tourgroups/{tourGroupId}/export-reference",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: { type: { name: "Stream" }, serializedName: "parsedResponse" }
        }
    },
    urlParameters: [Parameters.$host, Parameters.tourGroupId],
    headerParameters: [Parameters.accept3],
    serializer
};
const getSnbTourOperationSpec = {
    path: "/api/v2/tours/{tourId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.SnbTourModelApiResponse
        },
        400: {
            bodyMapper: Mappers.SnbTourModelApiResponse
        },
        404: {
            bodyMapper: Mappers.SnbTourModelApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.tourId],
    headerParameters: [Parameters.accept],
    serializer
};
const getSnbToursOperationSpec = {
    path: "/api/v2/tours/list",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.SnbTourModelListApiResponse
        },
        400: {
            bodyMapper: Mappers.SnbTourListApiResponse
        }
    },
    queryParameters: [Parameters.detailed],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const importToursOperationSpec = {
    path: "/api/v2/tours/import",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SnbTourModelListApiResponse
        },
        400: {
            bodyMapper: Mappers.SnbTourModelListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const exportTourOperationSpec = {
    path: "/api/v2/tours/export/{tourId}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.SnbTourExportModelApiResponse
        },
        400: {
            bodyMapper: Mappers.SnbTourExportModelApiResponse
        },
        404: {
            bodyMapper: Mappers.SnbTourExportModelApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.tourId],
    headerParameters: [Parameters.accept],
    serializer
};
const getTourByExternalIdOperationSpec = {
    path: "/api/tours/{externalTourId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.TourDtoApiResponse
        },
        403: {
            bodyMapper: Mappers.TourDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.TourDtoApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.externalTourId],
    headerParameters: [Parameters.accept],
    serializer
};
const getListOfToursOperationSpec = {
    path: "/api/tours",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.TourDtoListApiResponse
        },
        400: {
            bodyMapper: Mappers.TourDtoListApiResponse
        },
        403: {
            bodyMapper: Mappers.TourDtoListApiResponse
        },
        404: {
            bodyMapper: Mappers.TourDtoListApiResponse
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const createUserOperationSpec = {
    path: "/api/users/create",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        201: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse
        }
    },
    requestBody: Parameters.body60,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const requestResetPasswordOperationSpec = {
    path: "/api/users/requestresetpassword",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.email],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const resetPasswordOperationSpec = {
    path: "/api/users/resetpassword",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    queryParameters: [Parameters.login, Parameters.newPassword],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const verifyUserTokenOperationSpec = {
    path: "/api/users/verify/{email}/{token}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.StringApiResponse
        },
        400: {
            bodyMapper: Mappers.StringApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.email1, Parameters.token],
    headerParameters: [Parameters.accept],
    serializer
};
const setPasswordByUserOperationSpec = {
    path: "/api/users/set-password-by-user",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    requestBody: Parameters.body61,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const enableUserOperationSpec = {
    path: "/api/users/enableuser/{login}/{block}",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.OkResponseApiResponse
        },
        400: {
            bodyMapper: Mappers.OkResponseApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.login1, Parameters.block],
    headerParameters: [Parameters.accept],
    serializer
};
const confirmUserOperationSpec = {
    path: "/api/users/confirm",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse
        }
    },
    requestBody: Parameters.body62,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getBlockedUsersOperationSpec = {
    path: "/api/users/blocked",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        }
    },
    queryParameters: [
        Parameters.take,
        Parameters.skip,
        Parameters.searchString,
        Parameters.sortOrder
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getUsersOperationSpec = {
    path: "/api/users",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        }
    },
    queryParameters: [
        Parameters.take,
        Parameters.skip,
        Parameters.searchString,
        Parameters.sortOrder
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const updateUserOperationSpec = {
    path: "/api/users",
    httpMethod: "PUT",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        403: {
            bodyMapper: Mappers.UserDTOApiResponse
        }
    },
    requestBody: Parameters.body63,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const getUserByLoginOperationSpec = {
    path: "/api/users/bylogin/{login}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.login1],
    headerParameters: [Parameters.accept],
    serializer
};
const getUserByIdOperationSpec = {
    path: "/api/users/byid/{userId}",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOApiResponse
        }
    },
    urlParameters: [Parameters.$host, Parameters.userId],
    headerParameters: [Parameters.accept],
    serializer
};
const getUsersWithRolesOperationSpec = {
    path: "/api/users/with-roles",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        },
        400: {
            bodyMapper: Mappers.UserDTOPaginatedResultApiResponse
        }
    },
    queryParameters: [
        Parameters.take,
        Parameters.skip,
        Parameters.searchString,
        Parameters.sortOrder
    ],
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const getAdminUsersOperationSpec = {
    path: "/api/userscover/sausers",
    httpMethod: "GET",
    responses: {
        200: {
            bodyMapper: Mappers.UserPaginatedResultApiResponse
        },
        400: {
            bodyMapper: Mappers.ObjectApiResponse
        },
        403: {
            bodyMapper: Mappers.ProblemDetails
        }
    },
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept],
    serializer
};
const linkUserAccessToProgramAndBudgetPostOperationSpec = {
    path: "/user-program-access",
    httpMethod: "POST",
    responses: {
        200: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        },
        201: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        }
    },
    requestBody: Parameters.body64,
    urlParameters: [Parameters.$host],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
const linkUserAccessToProgramAndBudgetPatchOperationSpec = {
    path: "/user-program-access/{id}",
    httpMethod: "PATCH",
    responses: {
        200: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        },
        400: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        },
        404: {
            bodyMapper: Mappers.UserToBudgetAccessDtoApiResponse
        }
    },
    requestBody: Parameters.body64,
    urlParameters: [Parameters.$host, Parameters.id1],
    headerParameters: [Parameters.accept, Parameters.contentType1],
    mediaType: "json",
    serializer
};
